import statuses from "../../../_config/statuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";
const { API_URL, USERS } = address;
const {
  STATUS_OK,
  STATUS_UNAUTHORIZED,
  STATUS_FORBIDDEN,
  INTERNAL_ERROR,
} = statuses;

const AddNewUser = (
  username,
  password,
  email,
  first_name,
  last_name,
  dispatchUserAdded,
  dispatchUserAuthError,
  dispatchUserAddingFailed
) => {
  const options = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authentication: "Token" + localStorage.getItem("token"),
    },
    body: JSON.stringify({ username, password, email, first_name, last_name }),
  };

  fetch(`${API_URL}${USERS}`, options)
    .then((response) => {
      if (response.status < INTERNAL_ERROR) {
        return response.json().then((data) => {
          return { status: response.status, data };
        });
      } else {
        console.log("Server Error!");
        throw response;
      }
    })
    .then((response) => {
      console.log(response);
      if (response.status === STATUS_OK) {
        dispatchUserAdded(response);
      } else if (
        response.status === STATUS_FORBIDDEN ||
        response.status === STATUS_UNAUTHORIZED
      ) {
        return dispatchUserAuthError(response);
      } else {
        return dispatchUserAddingFailed(response);
      }
    });
};

export default AddNewUser;
