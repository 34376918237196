import eventStatuses from "../../_config/eventStatuses";

const {
  GO_TO_USERSLIST,
  GO_TO_USER_ADD,
  GO_TO_USER_EDIT,

  USER_ADDED,
  AUTHENTICATION_ERROR,
  USER_ADDING_FAILED,
  GET_USERS,
  EDIT_USER,
  DEACTIVATE_USER,
  DELETE_USER,
  ACTIVATE_USER,
  GET_EMAIL,
} = eventStatuses.users;

const initialState = {
  componentToGenerate: "",
  userList: [],
  editUserID: "",
  errorMessage: "",
  inputMessages: {},
  avatarId: "",
  avatarUrl: "",
  email: "",
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case GO_TO_USERSLIST:
      return {
        ...state,
        ...action.data,
        componentToGenerate: "user-list",
      };

    case GO_TO_USER_ADD:
      return {
        ...state,
        ...action.data,
        componentToGenerate: "user-add",
      };

    case GO_TO_USER_EDIT:
      console.log(action.id);
      return {
        ...state,
        ...action.data,
        componentToGenerate: "user-edit",
        editUserID: action.id,
      };

    case USER_ADDED:
      console.log(action.data.success);
      return {
        ...state,
        ...action.data,
        errorMessage: `${action.data.success}`,
      };

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...action.data,
        errorMessage: "Something went wrong.",
      };

    case USER_ADDING_FAILED:
      console.log(action.data);
      return {
        ...state,
        ...action.data,
        inputMessages: action.data,
        errorMessage:
          "The user has not been added. Please correct data implemented to a form.",
      };
    case DELETE_USER:
      return {
        ...state,
        userList: [...state.userList].filter(
          (user) => user.id !== action.payload.id
        ),
      };
    case GET_USERS:
      return {
        ...state,
        userList: action.payload,
      };
    case DEACTIVATE_USER:
      return {
        ...state,
        userList: state.userList.map((user) => {
          if (user.id !== action.payload.id) {
            return user;
          }
          return {
            ...user,
            ...action.payload,
          };
        }),
      };
    case ACTIVATE_USER:
      return {
        ...state,
        userList: state.userList.map((user) => {
          if (user.id !== action.payload.id) {
            return user;
          }
          return {
            ...user,
            ...action.payload,
          };
        }),
      };

    case EDIT_USER:
      return {
        ...state,
        userList: state.userList.map((user) => {
          if (user.id !== action.payload.id) {
            return user;
          }
          return {
            ...user,
            ...action.payload,
          };
        }),
      };
    case GET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
}
