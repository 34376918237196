import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import uniApp from "../_store/_reducers";

import persistToken from "../_store/_middleware/auth";

const store = createStore(
  uniApp,

  compose(
    applyMiddleware(thunk, persistToken),
    window.devToolsExtension
      ? window.devToolsExtension({ trace: true })
      : (f) => f
  )
);

export default store;
