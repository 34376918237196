import fetchLogin from "./services/LoginService";
import fetchRegister from "./services/RegisterUserService";

import eventStatuses from "../../_config/eventStatuses";

const {
  REGISTRATION_SUCCESFULL,
  REGISTRATION_ERROR,
  REGISTRATION_FAILED,
  PASSWORD_TOO_SHORT,
  PASSWORD_INCORRECT,
  PASSWORDS_NOT_MATCH,
  USER_LOADED,
  LOGIN_SUCCESSFUL,
  AUTHENTICATION_ERROR,
  LOGIN_FAILED,
  LOGOUT_SUCCESSFUL,
  REGISTRATION_SUCCESFULL_NEW_RECIEPENT,
} = eventStatuses.auth;

export const register = (
  username,
  password,
  passwordConfirm,
  email,
  first_name,
  last_name,
  stayLogged,
  profile,
  groups
) => {
  return (dispatch, getState) => {
    const validPassLength = 8;
    const validCriteria = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{7,}$/g;
    const passToValid = password;
    let validMatch;

    if (password === passwordConfirm) {
      if ((validMatch = validCriteria.exec(passToValid)) !== null) {
        if (validMatch.index === validCriteria.lastIndex) {
          validCriteria.lastIndex++;
        }

        const dispatchRegistrationSuccessful = function (response) {
          dispatch({
            type: REGISTRATION_SUCCESFULL,
            data: response.data,
          });
        };
        const dispatchRegistrationError = function (response) {
          dispatch({ type: REGISTRATION_ERROR, data: response.data });
          throw response.data;
        };
        const dispatchRegistrationFailed = function (response) {
          dispatch({ type: REGISTRATION_FAILED, data: response.data });
          throw response.data;
        };

        return fetchRegister(
          username,
          password,
          email,
          first_name,
          last_name,
          profile,
          groups,
          dispatchRegistrationSuccessful,
          dispatchRegistrationError,
          dispatchRegistrationFailed
        );
      } else if (password.length < validPassLength) {
        dispatch({ type: PASSWORD_TOO_SHORT });
      } else {
        dispatch({ type: PASSWORD_INCORRECT });
      }
    } else {
      dispatch({ type: PASSWORDS_NOT_MATCH });
    }
  };
};

export const login = (username, password, stayLogged) => {
  return (dispatch, getState) => {
    const dispatchLoginSuccessful = function (response, username, stayLogged) {
      dispatch({
        type: LOGIN_SUCCESSFUL,
        data: response.data,
        user: username,
        stayLogged,
      });
      dispatch({ type: USER_LOADED, user: username });
      return response.data, username, stayLogged;
    };

    const dispatchUserAuthError = function (response) {
      dispatch({ type: AUTHENTICATION_ERROR, data: response.data });
      throw response.data;
    };

    const dispatchLoginFailed = function (response) {
      dispatch({ type: LOGIN_FAILED, data: response.data });
      throw response.data;
    };

    return fetchLogin(
      username,
      password,
      stayLogged,
      dispatchLoginSuccessful,
      dispatchUserAuthError,
      dispatchLoginFailed
    );
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    return dispatch({ type: LOGOUT_SUCCESSFUL });
  };
};

export const registerNewReciepent = (
  username,
  password,
  passwordConfirm,
  email,
  first_name,
  last_name,
  stayLogged,
  profile,
  groups
) => {
  return (dispatch, getState) => {
    const validPassLength = 8;
    const validCriteria = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{7,}$/g;
    const passToValid = password;
    let validMatch;

    if (password === passwordConfirm) {
      if ((validMatch = validCriteria.exec(passToValid)) !== null) {
        if (validMatch.index === validCriteria.lastIndex) {
          validCriteria.lastIndex++;
        }

        const dispatchRegistrationSuccessful = function (response) {
          dispatch({
            type: REGISTRATION_SUCCESFULL_NEW_RECIEPENT,
            data: response.data,
          });
        };
        const dispatchRegistrationError = function (response) {
          dispatch({ type: REGISTRATION_ERROR, data: response.data });
          throw response.data;
        };
        const dispatchRegistrationFailed = function (response) {
          dispatch({ type: REGISTRATION_FAILED, data: response.data });
          throw response.data;
        };

        return fetchRegister(
          username,
          password,
          email,
          first_name,
          last_name,
          profile,
          groups,
          dispatchRegistrationSuccessful,
          dispatchRegistrationError,
          dispatchRegistrationFailed
        );
      } else if (password.length < validPassLength) {
        dispatch({ type: PASSWORD_TOO_SHORT });
      } else {
        dispatch({ type: PASSWORD_INCORRECT });
      }
    } else {
      dispatch({ type: PASSWORDS_NOT_MATCH });
    }
  };
};
