import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { remind } from "../../_store/_actions";
import { remindSendPasswordEmail } from "../../_store/_actions/remind";
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikWidgetContent,
  UikWidgetBottomCta,
  UikHeadline,
} from "../../@uik";
import AppLogo from "../img/matters-esignatures-black.png";
class RemindPassSend extends Component {
  state = {
    email: "",
    emailInputError: "",
    phase: "presend",
  };

  componentDidMount() {
    this.props.emailToSend &&
      this.setState({
        email: this.props.emailToSend,
      });
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (this.state.email === "") {
      this.setState({
        emailInputError: "Required",
      });
    } else {
      await this.props.remindSendPasswordEmail(this.state.email);
      this.setState({
        phase: "sended",
      });
    }
  };

  getRemindPassBody = (phase) => {
    switch (phase) {
      case "presend":
        return (
          <div className="login-wrap">
            <UikWidget padding>
              <UikHeadline className="form-headline">
                <img
                  className="remind__password__image__container"
                  src={AppLogo}
                />
              </UikHeadline>
              <UikWidgetContent>
                <form onSubmit={this.handleEmailSubmit}>
                  <UikFormInputGroup>
                    {this.props.emailToSend ? (
                      <UikInput
                        className="email-input"
                        type="email"
                        label="Your email"
                        errorMessage={this.state.emailInputError}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                      />
                    ) : (
                      <UikInput
                        className="email-input"
                        type="email"
                        label="Your email"
                        errorMessage={this.state.emailInputError}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                      />
                    )}

                    <UikButton
                      style={{ width: "100%" }}
                      primary
                      lg
                      type="submit"
                    >
                      Send
                    </UikButton>

                    <div>{this.props.errorMessage}</div>
                  </UikFormInputGroup>
                </form>
              </UikWidgetContent>
              <UikWidgetBottomCta Component={Link} to="/">
                <p>
                  back to <span style={{ color: "#1665D8" }}>MATTERS eSignatures</span>
                </p>
              </UikWidgetBottomCta>
            </UikWidget>
          </div>
        );
      case "sended":
        return (
          <div className="login-wrap">
            <UikWidget padding>
              <UikWidgetContent>
                <h2>
                  On provided email instruction<br></br> to change password has
                  been send
                </h2>
              </UikWidgetContent>
            </UikWidget>
          </div>
        );
      default:
        break;
    }
  };

  render() {
    return this.getRemindPassBody(this.state.phase);
  }
}
const mapStateToProps = (state) => {
  return {
    emailToSend: state.remind.email,
    errorMessage: state.remind.errorMessage,

    isReadyToSend: state.remind.isOK,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    remindSendPasswordEmail: (email) =>
      dispatch(remindSendPasswordEmail(email)),
    remindSendMail: (email) => {
      return dispatch(remind.remindSendMail(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindPassSend);
