import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../../_store/_actions";
import {
  UikNavLink,
  UikNavPanel,
  UikNavSection,
  UikNavSectionTitle,
  UikNavTitle,
} from "../../../@uik";
import "../../../../src/@uik/styles.css";
import AppLogo from "../../../pages/img/matters-esignatures-white.svg";
import "../../../App/styles/AppNavigation.css";

class AppNavigation extends Component {
  handlePremissions = (value) => {
    switch (value) {
      case 1:
        return (
          <UikNavPanel className="navigation-main-panel">
            <img className="app__navigation__logo__container" src={AppLogo} />
            <UikNavSection className="navigation-main-section">
              <UikNavSectionTitle>Menu</UikNavSectionTitle>
              <UikNavLink highlighted Component={NavLink} to="./docs">
                Documents
              </UikNavLink>
              <UikNavLink onClick={this.props.logout}>Log out</UikNavLink>
            </UikNavSection>
          </UikNavPanel>
        );
      case 2:
        return (
          <UikNavPanel className="navigation-main-panel">
            <img className="app__navigation__logo__container" src={AppLogo} />
            <UikNavSection className="navigation-main-section">
              <UikNavSectionTitle>Menu</UikNavSectionTitle>
              <UikNavLink highlighted Component={NavLink} to="./docs">
                Documents
              </UikNavLink>
              <UikNavLink highlighted Component={NavLink} to="./profile">
                Profile
              </UikNavLink>
              <UikNavLink highlighted Component={NavLink} to="./verification">
                Verificate document
              </UikNavLink>
              <UikNavLink onClick={this.props.logout}>Log out</UikNavLink>
            </UikNavSection>
          </UikNavPanel>
        );
      case 3:
        return (
          <UikNavPanel className="navigation-main-panel">
            <img className="app__navigation__logo__container" src={AppLogo} />
            <UikNavSection className="navigation-main-section">
              <UikNavSectionTitle>MENU</UikNavSectionTitle>
              <UikNavLink highlighted Component={NavLink} to="./docs">
                Documents
              </UikNavLink>
              <UikNavLink highlighted Component={NavLink} to="./verification">
                Verificate document
              </UikNavLink>
              <UikNavSectionTitle>SETTINGS</UikNavSectionTitle>
              <UikNavLink highlighted Component={NavLink} to="./profile">
                Your profile
              </UikNavLink>
              <UikNavLink highlighted Component={NavLink} to="./organisation">
                Organization
              </UikNavLink>
              <UikNavLink highlighted Component={NavLink} to="./users">
                Users
              </UikNavLink>

              <UikNavLink onClick={this.props.logout}>Log out</UikNavLink>
            </UikNavSection>
          </UikNavPanel>
        );
      default:
        break;
    }
  };

  render() {
    return this.handlePremissions(this.props.authData.groups[0]);
  }
}
const mapStateToProps = (state) => {
  return {
    authData: state.authData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(auth.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation);
