import React, { Component } from "react";
import { connect } from "react-redux";
import { getAuthData } from "../_store/_actions/services/getAuthData";
import {
  UikButton,
  UikContainerVertical,
  UikContainerHorizontal,
  UikLayoutMain,
  UikInput,
  UikFormInputGroup,
  UikTopBar,
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
} from "../@uik";
import "../@uik/styles.css";
import {
  getLogo,
  uploadLogo,
  addOrganisation,
  getOrganisation,
} from "../_store/_actions/services/organizationServices";
import AppBurgerMenu from "./components/global/AppBurgerMenu";
import AppNavigation from "./components/global/AppNavigation";
import AppLoggedUser from "./components/global/AppLoggedUser";
import logo_default from "./img/logo_default.png";
import "../App/styles/organisationPage.css";
class OrganisationPage extends Component {
  state = {
    logo: null,
    name: null,
    street: null,
    post_code: null,
    city: null,
    organisations: null,
  };

  componentDidMount() {
    this.handleInitData();
    this.props.getAuthData();
    if (this.props.organisations.logo !== null) {
      this.props.getLogo(this.props.organisations.logo);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.logoId !== prevProps.logoId) {
      this.props.getLogo(this.props.logoId);
    }
    if (this.props.logoUrl !== prevProps.logoUrl) {
      this.props.getLogo(this.props.organisations.logo);
    }
  }
  handleLogoChange = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await this.props.uploadLogo(formData);

    this.setState({
      logo: this.props.logoId,
    });
    this.props.getLogo(this.state.logo);
  };

  handleInitData = async () => {
    await this.props.getOrganisation();
    this.setState({
      name: this.props.organisations.name,
      street: this.props.organisations.street,
      post_code: this.props.organisations.post_code,
      city: this.props.organisations.city,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleAdressChange = (e) => {
    this.setState({
      street: e.target.value,
    });
  };

  handlePostCodeChange = (e) => {
    this.setState({
      post_code: e.target.value,
    });
  };

  handleCityChange = (e) => {
    this.setState({
      city: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.props.AddOrganisation(this.state, this.props.logoId);
    this.props.getLogo(this.props.organisations.state.logo);
  };

  render() {
    return (
      <div className="page-wrap">
        <UikContainerHorizontal>
          {this.props.isMenuOpen ? <AppNavigation /> : null}
          <UikContainerVertical>
            <UikTopBar className="application-top-bar">
              <AppBurgerMenu />
              <AppLoggedUser />
            </UikTopBar>
            <UikLayoutMain>
              <UikWidget className="organisation-content">
                <UikWidgetHeader
                  rightEl={
                    <UikButton
                      className="organisation-btn"
                      primary
                      type="submit"
                      form="organisation-form"
                    >
                      Save changes
                    </UikButton>
                  }
                >
                  <h3 id="organisation__page__header__h3">Organisation</h3>
                </UikWidgetHeader>
                <UikWidgetContent>
                  <form id="organisation-form" onSubmit={this.handleSubmit}>
                    <UikFormInputGroup direction="horizontal">
                      <UikFormInputGroup className="add-logo-input-wrap">
                        <h3>Organization's logo</h3>
                        <label htmlFor="add-logo-input">
                          <img
                            src={
                              this.props.logoUrl !== undefined
                                ? this.props.logoUrl
                                : logo_default
                            }
                            alt="add organisation's logo"
                          />
                        </label>
                        <UikInput
                          className="add-logo-input"
                          type="file"
                          onChange={this.handleLogoChange}
                        />
                      </UikFormInputGroup>

                      <UikFormInputGroup>
                        <h3>Organization data</h3>
                        <UikInput
                          className="org-form name-input"
                          label="Name"
                          type="text"
                          value={this.state.name}
                          onChange={this.handleNameChange}
                        />

                        <UikInput
                          className="org-form adress-input"
                          label="Adress"
                          type="text"
                          value={this.state.street}
                          onChange={this.handleAdressChange}
                        />
                        <UikFormInputGroup direction="horizontal">
                          <UikInput
                            className="org-form postcode-input"
                            label="Post code"
                            type="text"
                            value={this.state.post_code}
                            onChange={this.handlePostCodeChange}
                          />
                          <UikInput
                            className="org-form city-input"
                            label="City"
                            type="text"
                            value={this.state.city}
                            onChange={this.handleCityChange}
                          />
                        </UikFormInputGroup>
                      </UikFormInputGroup>
                    </UikFormInputGroup>
                  </form>
                </UikWidgetContent>
              </UikWidget>
            </UikLayoutMain>
          </UikContainerVertical>
        </UikContainerHorizontal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUser: state.auth.user,
    userList: state.users.userList,
    isMenuOpen: state.menu.isMenuOpen,
    organisations: state.organisations,
    authData: state.authData,
    logoId: state.organisations.logoId,
    logoUrl: state.organisations.logoUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogo: (id) => dispatch(getLogo(id)),
    uploadLogo: (logo) => dispatch(uploadLogo(logo)),
    getAuthData: () => dispatch(getAuthData()),
    getOrganisation: () => dispatch(getOrganisation()),
    AddOrganisation: (organisation, logoId) =>
      dispatch(addOrganisation(organisation, logoId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationPage);
