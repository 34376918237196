import eventStatuses from "../../_config/eventStatuses";

const {
  REGISTRATION_SUCCESFULL,
  REGISTRATION_ERROR,
  REGISTRATION_FAILED,
  PASSWORD_TOO_SHORT,
  PASSWORD_INCORRECT,
  PASSWORDS_NOT_MATCH,
  USER_LOADED,
  LOGIN_SUCCESSFUL,
  AUTHENTICATION_ERROR,
  LOGIN_FAILED,
  LOGOUT_SUCCESSFUL,
  REGISTRATION_SUCCESFULL_NEW_RECIEPENT,
} = eventStatuses.auth;

const initialState = {
  token: localStorage.getItem("token"),
  autoLogin: localStorage.getItem("autologin"),
  isAuthenticated: null,
  isModalOpen: null,
  newReciepent: "",
  newReciepentId: "",
  user: localStorage.getItem("username"),
  errorMessage: "",
  loginMessage: "Forgotten password? Just click here",
  registrationMessage:
    "The registration form was generated for your email adress",
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case REGISTRATION_SUCCESFULL_NEW_RECIEPENT:
      return {
        ...state,
        newReciepent: action.data.email,
        newReciepentId: action.data.profile.id,
        newReciepentGroup: action.data.groups[0],
        isModalOpen: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
      };

    case REGISTRATION_SUCCESFULL:
      return {
        ...state,
        ...action.data,
      };

    case REGISTRATION_ERROR:
      return {
        ...state,
        ...action.data,
        errorMessage: "Something went wrong.",
      };

    case REGISTRATION_FAILED:
      return {
        ...state,
        ...action.data,
        errorMessage: "Registration data is incorrect.",
      };

    case PASSWORD_TOO_SHORT:
      return {
        ...state,
        errorMessage: "Your password must contain min. 8 characters.",
      };

    case PASSWORD_INCORRECT:
      return {
        ...state,
        errorMessage:
          "Your password must include a small letter, a big letter and a number.",
      };

    case PASSWORDS_NOT_MATCH:
      return {
        ...state,
        errorMessage:
          "Passwords inputs' values don't match. Both of them must be identical.",
      };

    case LOGIN_SUCCESSFUL:
      console.log(action.stayLogged);

      return {
        ...state,
        ...action.data,
        isAuthenticated: true,
        user: localStorage.getItem("username"),
        autoLogin: action.stayLogged,
        errors: null,
      };

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        ...action.data,
        errorMessage: "Something went wrong.",
      };

    case LOGIN_FAILED:
      return {
        ...state,
        ...action.data,
        errorMessage: "Failed login or password!",
      };

    case LOGOUT_SUCCESSFUL:
      return {
        ...state,
        token: null,
        autoLogin: false,
        user: null,
        isAuthenticated: false,
      };

    default:
      return state;
  }
}
