import statuses from "../../../_config/statuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";

const {
  STATUS_OK,
  STATUS_UNAUTHORIZED,
  STATUS_FORBIDDEN,
  INTERNAL_ERROR,
} = statuses;

const { API_URL, LOGIN } = address;

const fetchLogin = (
  username,
  password,
  stayLogged,
  dispatchLoginSuccessful,
  dispatchUserAuthError,
  dispatchLoginFailed
) => {
  const options = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  };

  fetch(`${API_URL}${LOGIN}`, options)
    .then((response) => {
      if (response.status < INTERNAL_ERROR) {
        return response.json().then((data) => {
          return { status: response.status, data };
        });
      } else {
        console.log("Server Error!");
        throw response;
      }
    })
    .then((response) => {
      console.log(response);
      if (response.status === STATUS_OK) {
        dispatchLoginSuccessful(response, username, stayLogged);
      } else if (
        response.status === STATUS_FORBIDDEN ||
        response.status === STATUS_UNAUTHORIZED
      ) {
        dispatchUserAuthError(response);
      } else {
        dispatchLoginFailed(response);
      }
    });
};

export default fetchLogin;
