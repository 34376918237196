import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { users } from "../../../_store/_actions";
import { getAuthData } from "../../../_store/_actions/services/getAuthData";
import UsersDropdownMenu from "./UsersDropdownMenu";
import { getUserList } from "../../../_store/_actions/users";
import {
  UikButton,
  UikTag,
  UikWidgetContent,
  UikWidgetHeader,
  UikWidgetTable,
} from "../../../@uik";

import "react-toastify/dist/ReactToastify.css";
class UsersTable extends Component {
  state = {
    componentToGenerate: "user-add",
    openAccountOptions: false,
  };
  componentDidMount() {
    this.props.getUserList();
    this.props.getAuthData();
  }

  handleChangeWindow = (e) => {
    this.props.switchUsersListWindows(this.state.componentToGenerate);
  };

  toggleAccountOptions = (e) => {
    this.setState({ openAccountOptions: !this.state.openAccountOptions });
  };

  getUserGroupName = (groups) => {
    if (groups === 3) {
      return "Owner";
    }
    if (groups === 2) {
      return "Employee";
    }
    if (groups === 1) {
      return "Guest";
    }
    return "";
  };

  getUserStatus = (status) => {
    switch (status) {
      case true:
        return (
          <UikTag color="green" fill>
            Active
          </UikTag>
        );
      case false:
        return (
          <UikTag color="red" fill>
            Inactive
          </UikTag>
        );
      default:
        return null;
    }
  };

  renderUserList() {
    return (
      <>
        {this.props.userList &&
          this.props.userList.map((user) => {
            return (
              <React.Fragment key={user.id}>
                <tr className="user-list-row">
                  <td>
                    {user.first_name} {user.last_name}
                    <br></br>
                    {user.email}
                  </td>
                  <td>{user.profile?.position}</td>
                  <td>{this.getUserGroupName(user.groups[0])}</td>
                  <td>{user.profile?.company}</td>
                  <td>{this.getUserStatus(user.is_active)}</td>
                  <td>
                    <div className="userlist-dropdown">
                      <UikButton
                        className="userlist-opts-trigger"
                        onClick={this.toggleAccountOptions}
                      >
                        ...
                      </UikButton>
                      {this.state.openAccountOptions ? (
                        <div className="userlist-opts-container">
                          <UsersDropdownMenu
                            changeComponent={this.handleChangeWindow}
                            userid={user.id}
                            username={user.username}
                            active={user.is_active}
                          />
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
      </>
    );
  }
  render() {
    return (
      <>
        <UikWidgetHeader
          rightEl={
            <UikButton
              className="users-add-btn"
              Component={Link}
              to="/register"
              primary
            >
              Add user
            </UikButton>
          }
        >
          Users
        </UikWidgetHeader>
        <UikWidgetContent>
          <UikWidgetTable>
            <thead>
              <tr>
                <th>Full name</th>
                <th>Job title</th>
                <th>Role</th>
                <th>Organisation</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>{this.renderUserList()}</tbody>
          </UikWidgetTable>
        </UikWidgetContent>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentToGenerate: state.users.componentToGenerate,
    userList: state.users.userList,
    authData: state.authData,
    toastFlag: state.users.toastFlag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAuthData: () => dispatch(getAuthData()),
    getUserList: () => dispatch(getUserList()),
    showUserList: () => dispatch(users.showUserList()),

    switchUsersListWindows: (componentToGenerate) => {
      return dispatch(users.switchUsersListWindows(componentToGenerate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
