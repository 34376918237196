import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import {
  UikButton,
  UikContainerVertical,
  UikContainerHorizontal,
  UikLayoutMain,
  UikInput,
  UikFormInputGroup,
  UikTopBar,
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
} from "../@uik";
import "../@uik/styles.css";
import AppBurgerMenu from "./components/global/AppBurgerMenu";
import AppNavigation from "./components/global/AppNavigation";
import AppLoggedUser from "./components/global/AppLoggedUser";
import {
  getAuthData,
  changeAuthData,
} from "../_store/_actions/services/getAuthData";
import avatar_default from "./img/avatar_default.jpg";
import "../App/styles/profilePage.css";
import { uploadAvatar, getAvatar } from "../_store/_actions/users";
class ProfilePage extends Component {
  state = {
    avatarPlaceholder: { content: "", color: "violet" },
    refreshRequired: false,
    first_name: "",
    last_name: "",

    profile: {
      position: "",
      avatar: this.props.avatarUrl ? this.props.avatarUrl : avatar_default,
    },
    username: "",
    email: "",
    id: "",
    groups: "",
  };

  componentDidMount() {
    this.handleInitData();
    if (this.props.authData.profile.avatar !== null) {
      this.props.getAvatar(this.props.authData.profile.avatar);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.avatarId !== prevProps.avatarId) {
      this.props.getAvatar(this.props.authData.profile.avatar);
      this.setState({
        ...this.state,
        profile: {
          ...this.state.profile,
        },
      });
    }
    if (this.props.avatarUrl !== prevProps.avatarUrl) {
      this.props.getAvatar(this.props.authData.profile.avatar);
      this.setState({
        ...this.state,
        profile: {
          ...this.state.profile,
        },
      });
    }
  }

  handleInitData = async () => {
    await this.props.getAuthData();
    this.setState({
      first_name: this.props.authData.first_name,
      last_name: this.props.authData.last_name,
      profile: {
        ...this.state.profile,
        position: this.props.authData.profile.position,
      },
    });
  };

  notify = () =>
    toast("Your data has been changed", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 1,
    });

  handleAvatarChange = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await this.props.uploadAvatar(formData);

    this.setState({
      profile: {
        position: this.props.authData.profile?.position,
        avatar: this.props.avatarId,
      },
    });
    this.props.getAvatar(this.state.profile.avatar);
  };

  handleUsernameChange = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleFirstNameChange = (e) => {
    this.setState({
      first_name: e.target.value,
    });
  };

  handleLastNameChange = (e) => {
    this.setState({
      last_name: e.target.value,
    });
  };

  handlePositionChange = (e) => {
    this.setState({
      profile: {
        ...this.state.profile,
        position: e.target.value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.changeAuthData(this.state, this.props.avatarId);

    this.notify();
  };
  getUserGroup = (group) => {
    if (group === 3) {
      return "Owner";
    }
    if (group === 2) {
      return "Employee";
    }
    if (group === 1) {
      return "Guest";
    }
    return "";
  };
  render() {
    const { email, groups } = this.props.authData;

    return (
      <div className="page-wrap">
        <UikContainerHorizontal>
          {this.props.isMenuOpen ? <AppNavigation /> : null}
          <UikContainerVertical>
            <UikTopBar className="application-top-bar">
              <AppBurgerMenu />
              <AppLoggedUser />
            </UikTopBar>
            <UikLayoutMain>
              <ToastContainer />
              <UikWidget className="profile-content">
                <UikWidgetHeader
                  rightEl={
                    <UikButton
                      className="profile-btn"
                      primary
                      type="submit"
                      form="user-profile-form"
                    >
                      Save changes
                    </UikButton>
                  }
                >
                  <h3 id="profilePage__header__title__h3">User profile</h3>
                </UikWidgetHeader>
                <UikWidgetContent>
                  <form id="user-profile-form" onSubmit={this.handleSubmit}>
                    <UikFormInputGroup direction="horizontal">
                      <UikFormInputGroup className="add-image-input-wrap">
                        <h3>User's image</h3>
                        <label htmlFor="add-image-input">
                          <img
                            src={
                              this.props.avatarUrl !== undefined
                                ? this.props.avatarUrl
                                : avatar_default
                            }
                            alt="add user's avatar"
                          />
                        </label>
                        <UikInput
                          className="add-image-input"
                          type="file"
                          onChange={this.handleAvatarChange}
                        />
                      </UikFormInputGroup>

                      <UikFormInputGroup>
                        <h3>Personal data</h3>

                        <UikInput
                          readOnly
                          className="user-form email-input"
                          label="Email"
                          type="email"
                          value={email}
                          onChange={this.handleEmailChange}
                        />
                        <UikFormInputGroup direction="horizontal">
                          <UikInput
                            className="user-form first-name-input"
                            label="First name"
                            type="text"
                            value={this.state.first_name}
                            onChange={this.handleFirstNameChange}
                          />
                          <UikInput
                            className="user-form last-name-input"
                            label="Last name"
                            type="text"
                            value={this.state.last_name}
                            onChange={this.handleLastNameChange}
                          />
                        </UikFormInputGroup>

                        <UikInput
                          className="user-form position-input"
                          label="Job title"
                          type="text"
                          value={this.state.profile.position}
                          onChange={this.handlePositionChange}
                        />

                        <h3>Account's settings</h3>

                        <UikButton Component={Link} to="./password_reset">
                          Change password
                        </UikButton>
                        <UikInput
                          readOnly
                          className="user-form position-input"
                          label="Role"
                          type="text"
                          value={this.getUserGroup(groups[0])}
                        />
                      </UikFormInputGroup>
                    </UikFormInputGroup>
                  </form>
                </UikWidgetContent>
              </UikWidget>
            </UikLayoutMain>
          </UikContainerVertical>
        </UikContainerHorizontal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUser: state.auth.user,
    userList: state.users.userList,
    isMenuOpen: state.menu.isMenuOpen,
    authData: state.authData,
    avatarId: state.authData.avatarId,
    avatarUrl: state.authData.avatarUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAvatar: (id) => dispatch(getAvatar(id)),
    uploadAvatar: (attachment) => dispatch(uploadAvatar(attachment)),
    getAuthData: () => dispatch(getAuthData()),
    changeAuthData: (data, avatar) => dispatch(changeAuthData(data, avatar)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
