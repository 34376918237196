import React, { Component } from "react";
import "./App.scss";

import "../@uik/styles.css";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import passwordConfirmationPage from "../pages/components/passwordConfirmationPage";
import LoginPage from "../pages/LoginPage";
import RegistrationPage from "../pages/RegistrationPage";
import verificationPage from "../pages/verificationPage";
import RemindPassSend from "../pages/components/RemindPassSend";
import RemindPassReset from "../pages/components/RemindPassReset";
import UsersPage from "../pages/UsersPage";
import DocsPage from "../pages/DocsPage";
import ProfilePage from "../pages/ProfilePage";
import OrganisationPage from "../pages/OrganisationPage";
import RemindPassEmail from "../pages/components/users/RemindPassEmail";
import VerificationPageLogin from "../pages/components/VerificationPageLogin";
import { Provider, connect } from "react-redux";
import { auth } from "../_store/_actions";
import store from "../_store/store";
import DocsDetailsEmailed from "../pages/components/docs/DocsDetailsEmailed";
import changePassEmail from "../pages/components/users/changePassEmail";

class RootContainerComponent extends Component {
  PrivateRoute = ({ component: ChildComponent, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (this.props.auth.isLoading) {
            return <em>Loading...</em>;
          } else if (
            !this.props.auth.isAuthenticated &&
            !this.props.auth.autoLogin
          ) {
            return <Redirect to="/" />;
          } else {
            return <ChildComponent {...props} />;
          }
        }}
      />
    );
  };

  render() {
    let { PrivateRoute } = this;

    return (
      <Router>
        <div className="containter">
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/register" component={RegistrationPage} />
            <Route path="/password_send" component={RemindPassSend} />
            <Route path="/password_reset" component={RemindPassReset} />
            <PrivateRoute exact path="/users" component={UsersPage} />
            <PrivateRoute exact path="/docs" component={DocsPage} />
            <PrivateRoute exact path="/profile" component={ProfilePage} />
            <PrivateRoute
              exact
              path="/organisation"
              component={OrganisationPage}
            />
            <PrivateRoute
              exact
              path="/verification"
              component={verificationPage}
            />
            <Route
              exact
              path="/password_confirmation"
              component={passwordConfirmationPage}
            />
            <Route
              exact
              path="/documents/:DocumentId/sign_preview/:token"
              component={DocsDetailsEmailed}
            />
            <Route
              exact
              path="/verificate_document"
              component={VerificationPageLogin}
            />
            <Route
              exact
              path="/confirm_registration/:token"
              component={RemindPassEmail}
            />
            <Route
              exact
              path="/change_password/:token"
              component={changePassEmail}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loaduser: () => {
      return dispatch(auth.loadUser());
    },
  };
};

let RootContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RootContainerComponent);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer />
      </Provider>
    );
  }
}
