import eventStatuses from "../../_config/eventStatuses";

const {
  EMAIL_CORRECT,
  EMAIL_ERROR,
  EMAIL_INCORRECT,
  PASSWORD_SUCCESSFULLY_CHANGED,
  PASSWORD_CHANGE_FAIL,
  PASSWORD_CHANGE_INCORRECT,
  PASSWORD_TOO_SHORT,
  PASSWORD_INCORRECT,
} = eventStatuses.remind;

const initialState = {
  isOK: false,
  passChanged: false,
  email: localStorage.getItem("username"),
  newPassword: "",
  errorMessage: "",
  backMessage: `Back to application`,
};

export default function remind(state = initialState, action) {
  switch (action.type) {
    case EMAIL_CORRECT:
      return {
        ...state,
        ...action.data,
        isOK: true,
        errorMessage: `A message with a link to reset the password has been sent to the address: ${action.email}.`,
      };

    case EMAIL_ERROR:
      return {
        ...state,
        ...action.data,
        errorMessage: `${action.data.email}`,
      };

    case EMAIL_INCORRECT:
      return {
        ...state,
        ...action.data,
        errorMessage: `${action.data.email}`,
      };

    case PASSWORD_SUCCESSFULLY_CHANGED:
      return {
        ...state,
        ...action,
        newPassword: action.password,
        passChanged: true,
        errorMessage: "Your password was successfully changed.",
      };

    case PASSWORD_CHANGE_FAIL:
      return {
        ...state,
        ...action,
        errorMessage: "Something was wrong",
      };

    case PASSWORD_CHANGE_INCORRECT:
      console.log(action.data);
      return {
        ...state,
        ...action,
        errorMessage: "Changing password has failed.",
      };

    case PASSWORD_TOO_SHORT:
      return {
        ...state,
        errorMessage: "Your password must contain min. 8 characters.",
      };

    case PASSWORD_INCORRECT:
      return {
        ...state,
        errorMessage: `Your password must include a small letter, a big letter and a number.`,
      };

    default:
      return state;
  }
}
