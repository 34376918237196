import eventStatues from "../../_config/eventStatuses";
import address from "../../_config/address";
import fetch from "../../fetch";
const { SEND_DOCUMENT } = eventStatues.documents;

const { API_URL, DOCUMENTS } = address;

export const sendDocument = (document) => async (dispatch, getState) => {
  try {
    const documentId = getState().documents;
    const response = await fetch(`${API_URL}${DOCUMENTS}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        ...document,
        attachments: [documentId.docfileId],
        status: "DRAFT",
      }),
    });
    const currentDocument = await response.json();
    const currentDocumentId = currentDocument.id;

    const documentToSend = await fetch(
      `${API_URL}${DOCUMENTS}/${currentDocumentId}/send/`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ ...document, status: "SENT" }),
      }
    );

    const preparedDocument = await documentToSend.json();

    dispatch({ type: SEND_DOCUMENT, payload: preparedDocument });
  } catch (error) {
    console.log(error);
  }
};
