import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { auth } from "../../../_store/_actions";
import { users } from "../../../_store/_actions";
import { getUserList } from "../../../_store/_actions/users";

import {
  UikAvatar,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  UikTopBarSection,
} from "../../../@uik";

class AppLoggedUser extends Component {
  state = {
    loggedUser: this.props.user,
    avatarPlaceholder: { content: "", color: "violet" },
  };

  componentDidMount() {
    this.props.getUserList();
  }

  handleUsersAvatar = (e) => {
    if (this.props.userList) {
      return this.props.userList?.map((user, index) => {
        const { id, username, first_name, last_name } = user;
        const avatarPlaceholder = {
          content: first_name.charAt(0) + last_name.charAt(0),
          color: "violet",
        };
        let currentAvatar;

        const CustomDisplayComponent = ({}) => (
          <UikAvatar
            className="user-avatar"
            key={id}
            avatarPlaceholder={avatarPlaceholder}
          />
        );

        if (username === this.state.loggedUser) {
          currentAvatar = (
            <UikDropdown
              className="avatar-dropmenu"
              key={id}
              DisplayComponent={CustomDisplayComponent}
              position="bottomRight"
            >
              <UikDropdownItem Component={NavLink} to="./profile">
                Your profile
              </UikDropdownItem>

              <UikDropdownItem
                onClick={this.props.logout}
                className="user-drop-menu logout"
              >
                Logout
              </UikDropdownItem>
            </UikDropdown>
          );
        }
        return currentAvatar;
      });
    }
  };

  render() {
    return (
      <UikTopBarSection className="logged-user-section">
        {this.handleUsersAvatar()}
        <UikDivider margin direction="vertical" />
        <span> {this.props.user} </span>
      </UikTopBarSection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userList: state.users.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: () => dispatch(getUserList()),
    logout: () => dispatch(auth.logout()),
    showUserList: () => dispatch(users.showUserList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLoggedUser);
