import React, { Component } from "react";
import { connect } from "react-redux";

import { users } from "../_store/_actions";

import {
  UikContainerVertical,
  UikContainerHorizontal,
  UikLayoutMain,
  UikTopBar,
  UikWidget,
} from "../@uik";
import "../@uik/styles.css";

import AppBurgerMenu from "./components/global/AppBurgerMenu";
import AppNavigation from "./components/global/AppNavigation";
import AppLoggedUser from "./components/global/AppLoggedUser";
import UsersTable from "./components/users/UsersTable";
import UsersAdd from "./components/users/UsersAdd";
import UsersEdit from "./components/users/UsersEdit";

export class UsersPage extends Component {
  componentDidMount() {
    this.props.showUserList();
  }

  getProperComponent(component) {
    let properComponent;
    switch (component) {
      case "user-list":
        properComponent = <UsersTable />;
        break;
      case "user-add":
        properComponent = <UsersAdd />;
        break;
      case "user-edit":
        properComponent = <UsersEdit />;
        break;
      default:
        properComponent = <UsersTable />;
    }
    return properComponent;
  }

  render() {
    return (
      <div className="page-wrap">
        <UikContainerHorizontal>
          {this.props.isMenuOpen ? <AppNavigation /> : null}
          <UikContainerVertical>
            <UikTopBar className="application-top-bar">
              <AppBurgerMenu />
              <AppLoggedUser />
            </UikTopBar>
            <UikLayoutMain>
              <UikWidget className="users-content">
                {this.getProperComponent(this.props.componentToGenerate)}
              </UikWidget>
            </UikLayoutMain>
          </UikContainerVertical>
        </UikContainerHorizontal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentToGenerate: state.users.componentToGenerate,
    userList: state.users.userList,
    isMenuOpen: state.menu.isMenuOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showUserList: () => dispatch(users.showUserList()),
    switchUsersListWindows: (componentToGenerate) => {
      return dispatch(users.switchUsersListWindows(componentToGenerate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
