import sendMail from "./services/SendMailService";
import changePassword from "./services/PasswordService";
import address from "../../_config/address";
import eventStatuses from "../../_config/eventStatuses";
import fetch from "../../fetch";

const { API_URL, REMIND_PASSWORD, CHANGE_PASSWORD } = address;

const {
  EMAIL_CORRECT,
  EMAIL_ERROR,
  EMAIL_INCORRECT,
  PASSWORD_SUCCESSFULLY_CHANGED,
  PASSWORD_CHANGE_FAIL,
  PASSWORD_CHANGE_INCORRECT,
  PASSWORD_TOO_SHORT,
  PASSWORD_INCORRECT,
  EMAIL_SEND,
  CONFIRM_REGISTRATION,
} = eventStatuses.remind;

export const remindSendMail = (email) => {
  return (dispatch, getState) => {
    const dispatchEmailCorrect = function (response) {
      dispatch({ type: EMAIL_CORRECT, data: response.data, email });
      return response.data;
    };

    const dispatchEmailError = function (response) {
      dispatch({ type: EMAIL_ERROR, data: response.data });
      throw response.data;
    };

    const dispatchEmailIncorrect = function (response) {
      dispatch({ type: EMAIL_INCORRECT, data: response.data });
      throw response.data;
    };

    return sendMail(
      email,
      dispatchEmailCorrect,
      dispatchEmailError,
      dispatchEmailIncorrect
    );
  };
};

export const remindSendPasswordEmail = (email) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${REMIND_PASSWORD}/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });
    const payload = await response.json();
    dispatch({ TYPE: EMAIL_SEND, payload: payload });
  } catch (error) {
    console.log(error);
  }
};

export const remindChangePassword = (password, token) => {
  return (dispatch, getState) => {
    const validPassLength = 8;
    const validCriteria = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{7,}$/g;
    const passToValid = password;
    let validMatch;

    if ((validMatch = validCriteria.exec(passToValid)) !== null) {
      if (validMatch.index === validCriteria.lastIndex) {
        validCriteria.lastIndex++;
      }

      const dispatchPasswordChanged = function (response) {
        dispatch({ type: PASSWORD_SUCCESSFULLY_CHANGED, data: response.data });
        return response.data;
      };

      const dispatchPasswordChangeFail = function (response) {
        dispatch({ type: PASSWORD_CHANGE_FAIL, data: response.data });
        throw response.data;
      };

      const dispatchPasswordChangeIncorrect = function (response) {
        dispatch({ type: PASSWORD_CHANGE_INCORRECT, data: response.data });
        throw response.data;
      };

      return changePassword(
        password,
        token,
        dispatchPasswordChanged,
        dispatchPasswordChangeFail,
        dispatchPasswordChangeIncorrect
      );
    } else if (password.length < validPassLength) {
      dispatch({ type: PASSWORD_TOO_SHORT });
    } else {
      dispatch({ type: PASSWORD_INCORRECT });
    }
  };
};

export const changePasswordNew = (password, token) => async (dispatch) => {
  try {
    await fetch(`${API_URL}${CHANGE_PASSWORD}/${token}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ password: password }),
    });
  } catch (error) {
    console.log(error);
  }
};

export const confirmRegistration = (token) => async (dispatch) => {
  try {
    await fetch(`${API_URL}${CONFIRM_REGISTRATION}${token}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
};
