import React, { Component } from "react";
import { connect } from "react-redux";
import { docs } from "../_store/_actions";
import {
  UikContainerVertical,
  UikContainerHorizontal,
  UikLayoutMain,
  UikTopBar,
  UikWidget,
} from "../@uik";
import "../@uik/styles.css";
import AppBurgerMenu from "./components/global/AppBurgerMenu";
import AppNavigation from "./components/global/AppNavigation";
import AppLoggedUser from "./components/global/AppLoggedUser";
import DocsTable from "./components/docs/DocsTable";
import DocsAdd from "./components/docs/DocsAdd";
import DocsNewReceipent from "./components/docs/DocsNewReceipent";
import DocsView from "./components/docs/DocsView";
import DocsEdit from "./components/docs/DocsEdit";
class DocsPage extends Component {
  state = {
    loggedUser: this.props.user,
    avatarPlaceholder: { content: "", color: "violet" },
    selectedId: "",
  };

  getProperComponent(component) {
    let properComponent;
    switch (component) {
      case "docs-add":
        properComponent = <DocsAdd />;
        break;
      case "docs-view":
        properComponent = <DocsView id={this.state.selectedId} />;
        break;
      case "docs-new-rec":
        properComponent = <DocsNewReceipent />;
      case "docs-edit":
        properComponent = <DocsEdit id={this.state.selectedId} />;
        break;
      default:
        properComponent = (
          <DocsTable
            onItemSelected={(id) => this.setState({ selectedId: id })}
          />
        );
    }
    return properComponent;
  }

  render() {
    return (
      <div className="page-wrap">
        <UikContainerHorizontal>
          {this.props.isMenuOpen ? <AppNavigation /> : null}
          <UikContainerVertical>
            <UikTopBar className="application-top-bar">
              <AppBurgerMenu />
              <AppLoggedUser />
            </UikTopBar>
            <UikLayoutMain>
              <UikWidget className="docs-content">
                {this.getProperComponent(this.props.componentToGenerate)}
              </UikWidget>
            </UikLayoutMain>
          </UikContainerVertical>
        </UikContainerHorizontal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentToGenerate: state.docs.componentToGenerate,
    isMenuOpen: state.menu.isMenuOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchDocsListWindows: (componentToGenerate) => {
      return dispatch(docs.switchDocsListWindows(componentToGenerate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsPage);
