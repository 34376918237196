import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";

import { auth } from "../_store/_actions";

import {
  UikButton,
  UikCheckbox,
  UikFormInputGroup,
  UikHeadline,
  Uikon,
  UikInput,
  UikWidget,
  UikWidgetBottomCta,
  UikWidgetContent,
} from "../@uik";
import "../@uik/styles.css";
import AppLogo from "../pages/img/matters-esignatures-black.png";
import "../App/styles/loginPage.css";
class LoginPage extends Component {
  state = {
    username: "",
    password: "",
    usernameInputError: "",
    passwordInputError: "",
    showPassword: false,
    stayLogged: false,
  };

  handleUsernameChange = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleStayLogged = (e) => {
    this.setState({
      stayLogged: e.target.checked,
    });
  };

  handlePassVisibility = (e) => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.username === "") {
      this.setState({
        usernameInputError: "Required",
      });
    } else if (this.state.password === "") {
      this.setState({
        passwordInputError: "Required",
      });
    }

    this.props.login(
      this.state.username,
      this.state.password,
      this.state.stayLogged
    );
  };

  render() {
    if (this.props.isAuthenticated || this.props.autoLogin) {
      return <Redirect to="/docs" />;
    }
    return (
      <div className="login-wrap">
        <UikWidget padding>
          <img className="login__page__image__container" src={AppLogo} />
          <UikWidgetContent>
            <form onSubmit={this.handleSubmit}>
              <UikFormInputGroup className="login-form">
                <UikInput
                  className="login-form username-input"
                  label="Email"
                  errorMessage={this.state.usernameInputError}
                  type="text"
                  value={this.state.username}
                  onChange={this.handleUsernameChange}
                />
                <UikInput
                  className="login-form password-input"
                  label="Password"
                  type={this.state.showPassword ? "text" : "password"}
                  icon={
                    <Uikon
                      className="visibility-icon"
                      onClick={this.handlePassVisibility}
                    >
                      view_simple{" "}
                    </Uikon>
                  }
                  iconPosition="right"
                  errorMessage={this.state.passwordInputError}
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                />
                <UikCheckbox
                  className="login-form stay-logged-checkbox"
                  color="blue"
                  label="Keep me signed in"
                  checked={this.state.stayLogged}
                  onChange={this.handleStayLogged}
                />
                <UikButton primary lg type="submit">
                  Sign in
                </UikButton>
                <p className="login-error-message">{this.props.errorMessage}</p>
              </UikFormInputGroup>
            </form>
          </UikWidgetContent>
          <UikWidgetBottomCta Component={Link} to="/password_send">
            <p>Remind password </p>
          </UikWidgetBottomCta>
          <UikWidgetBottomCta Component={Link} to="/verificate_document">
            <p>Verify document</p>
          </UikWidgetBottomCta>
        </UikWidget>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loginMessage: state.auth.loginMessage,
    errorMessage: state.auth.errorMessage,
    autoLogin: state.auth.autoLogin,
    authData: state.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, stayLogged) => {
      return dispatch(auth.login(username, password, stayLogged));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
