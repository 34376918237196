import React, { Component } from "react";
import { connect } from "react-redux";
import address from "../../../_config/address";
import { docs } from "../../../_store/_actions";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import {
  UikWidget,
  UikButton,
  UikButtonGroup,
  UikDivider,
  Uikon,
  UikFormInputGroup,
  UikNavLink,
  UikInput,
  UikWidgetContent,
  UikWidgetHeader,
  UikContainerHorizontal,
} from "../../../@uik";
import {
  withdrawDocument,
  deleteDocument,
  changeDocumentStatus,
  dispatchDocumentId,
  getAttachment,
  dispatchDocumentName,
  seeDocument,
} from "../../../_store/_actions/services/documentServices";

import { getAuthData } from "../../../_store/_actions/services/getAuthData";
const { API_URL, ATTACHMENTS } = address;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;
class DocsView extends Component {
  state = {
    isModalOpen: false,
    attachments: "",
    docname: "",
    docsender: "",
    docdate: "",
    docid: "",
    docrecipient: [],
    componentToGenerate: "docs-list",
    refreshRequired: false,
    docstatus: "",
    assignations: [],
    status: "",
    name: "",
    isDocumentSigned: false,
    token: localStorage.getItem("token"),
  };

  handleChangeWindow = () => {
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };

  componentDidMount() {
    this.props.getAuthData();

    const currentDocument = this.props.documentsList.find(
      (item) => item.id === this.props.id
    );

    if (currentDocument) {
      this.setState({
        name: currentDocument.name,
        status: currentDocument.status,
        attachments: [
          ...this.state.attachments,
          currentDocument.attachments[0],
        ],
        docfile: currentDocument.attachments,
        docname: currentDocument.name,
        docsender: currentDocument.created_by.email,

        docdate: new Date(currentDocument.created_at).toLocaleString(),
        docid: currentDocument.id,

        docstatus: currentDocument.status,
        assignations: [...this.state.assignations].concat(
          currentDocument.assignations
        ),
      });
    } else {
      this.handleChangeWindow("docs-list");
    }
  }

  handleDocumentDelete = () => {
    const currentDocument = this.props.documentsList.find(
      (item) => item.id === this.props.id
    );
    if (currentDocument.status === "SENT") {
      alert("You can't delete sended document");
    }
    this.props.deleteDocument(this.props.id);

    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };

  getDocumentReciepents = () => {
    return this.state.assignations.map((reciepent) => {
      return reciepent.email + ", ";
    });
  };
  handleModalOpen = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
    this.props.getAttachment(this.state.attachments);
  };
  handleDocumentSign = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };
  handleStatusChange = (document, id) => {
    this.props.changeDocumentStatus(document, id);
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };

  handleDocumentSignConfirmation = () => {
    this.setState({
      isDocumentSigned: !this.state.isDocumentSigned,
    });
  };
  handleDocumentWithdrawal = (document, id) => {
    this.props.withdrawDocument(document, id);
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };
  handleDocumentDownload = () => {
    window.open(
      `${API_URL}${ATTACHMENTS}/${this.state.attachments}/download/?token="${this.state.token}"`,
      "_blank"
    );
  };

  render() {
    const currentUserEmail = this.props.authData.email;
    return (
      <React.Fragment>
        {this.state.isModalOpen === true ? (
          <UikWidget padding>
            <UikNavLink
              onClick={this.handleChangeWindow}
              className="active backlink"
            >
              <Uikon>arrow_left</Uikon>Back to document list
            </UikNavLink>
            <UikWidgetHeader
              rightEl={
                <UikButtonGroup>
                  {this.state.docstatus === "SENT" ? (
                    <UikButton
                      onClick={() =>
                        this.handleDocumentWithdrawal(
                          this.state,
                          this.state.docid
                        )
                      }
                    >
                      Withdraw document
                    </UikButton>
                  ) : null}
                  {this.state.docstatus === "SENT" &&
                  this.state.docsender === currentUserEmail ? (
                    <UikButton
                      className="profile-btn"
                      primary
                      onClick={this.handleDocumentSignConfirmation}
                      form="user-profile-form"
                    >
                      Sign document
                    </UikButton>
                  ) : null}
                </UikButtonGroup>
              }
            ></UikWidgetHeader>
            <UikContainerHorizontal>
              <div style={{ flexBasis: "50%" }}>
                <UikWidgetContent>
                  <Document
                    file={{
                      url: `${API_URL}${ATTACHMENTS}/${this.state.attachments}/download/`,
                      httpHeaders: {
                        Authorization: "Token " + localStorage.getItem("token"),
                      },
                    }}
                    renderMode="canvas"
                  >
                    <Page pageNumber={1} />
                  </Document>
                </UikWidgetContent>
              </div>
              <div style={{ flexBasis: "50%" }}>
                <UikWidget padding margin>
                  <UikWidgetContent>
                    <form>
                      <UikButton primary onClick={this.handleDocumentDownload}>
                        Download file
                      </UikButton>
                      <h3>Document details</h3>
                      <br />
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          readOnly
                          label="Name"
                          value={this.state.docname}
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup>
                        <UikInput
                          readOnly
                          label="Created at"
                          value={this.state.docdate}
                        />
                        <UikInput
                          readOnly
                          label="Document ID"
                          value={this.state.docid}
                        />
                      </UikFormInputGroup>
                      <h3>Sender</h3>
                      <br />
                      <UikFormInputGroup>
                        <UikInput
                          readOnly
                          label="sended by"
                          value={this.state.docsender}
                        />
                      </UikFormInputGroup>
                      <h3>Reciepents</h3>
                      <br />
                      <UikFormInputGroup>
                        <UikInput
                          readOnly
                          label="Reciepents"
                          value={this.getDocumentReciepents()}
                        />
                      </UikFormInputGroup>
                    </form>
                  </UikWidgetContent>
                </UikWidget>
              </div>
            </UikContainerHorizontal>
          </UikWidget>
        ) : (
          <div>
            <UikNavLink
              onClick={this.handleChangeWindow}
              className="active backlink"
            >
              <Uikon>arrow_left</Uikon>Back to document list
            </UikNavLink>
            <UikWidgetHeader
              rightEl={
                <UikButtonGroup>
                  {this.state.status === "DRAFT" ? (
                    <UikButton
                      onClick={() =>
                        this.handleStatusChange(this.state, this.state.docid)
                      }
                    >
                      SEND
                    </UikButton>
                  ) : null}
                  {this.state.status === "SENT" && (
                    <React.Fragment>
                      <UikButton
                        onClick={() =>
                          this.handleDocumentWithdrawal(
                            this.state,
                            this.state.docid
                          )
                        }
                      >
                        Withdraw
                      </UikButton>
                    </React.Fragment>
                  )}
                  <UikButton
                    className="users-add-btn"
                    Component={Link}
                    to={`/documents/${this.state.docid}/sign_preview/${this.props.authData.auth_token}`}
                    primary
                  >
                    Show details
                  </UikButton>
                </UikButtonGroup>
              }
            >
              <div>
                {this.state.docname.toUpperCase()}
                {this.props.errorMessage ? <UikDivider /> : null}
                <span>{this.props.errorMessage}</span>
              </div>
            </UikWidgetHeader>
            <UikWidgetContent>
              <form id="view-doc-form" onSubmit={this.handleSubmit}>
                <UikFormInputGroup className="view-doc-form">
                  <h3>Basic informations</h3>
                  <UikFormInputGroup direction="horizontal">
                    <UikInput
                      className="view-doc-form sender-input"
                      label="Sender"
                      type="text"
                      value={this.state.docsender}
                      readOnly
                    />

                    <UikInput
                      className="view-doc-form date-input"
                      label="Sent date"
                      type="text"
                      value={this.state.docdate}
                      readOnly
                    />
                  </UikFormInputGroup>
                  <UikInput
                    className="view-doc-form ID-input"
                    label="Document ID"
                    type="text"
                    value={this.state.docid}
                    readOnly
                  />
                  <UikInput
                    className="view-doc-form status-input"
                    label="Document status"
                    type="text"
                    value={this.state.status}
                    readOnly
                  />

                  <h3>Reciepents</h3>
                  <UikInput
                    className="view-doc-form recipient-input"
                    label="Reciepents"
                    type="text"
                    value={this.getDocumentReciepents()}
                    readOnly
                  />
                </UikFormInputGroup>
              </form>
            </UikWidgetContent>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userList: state.users.userList,
    componentToGenerate: state.docs.componentToGenerate,
    errorMessage: state.users.errorMessage,
    documentsList: state.documents.documentsList,
    attachmentUrl: state.documents.attachmentUrl,
    authData: state.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seeDocument: (id) => dispatch(seeDocument(id)),
    dispatchDocumentName: (name) => dispatch(dispatchDocumentName(name)),
    dispatchDocumentId: (id) => dispatch(dispatchDocumentId(id)),
    getAttachment: (id) => dispatch(getAttachment(id)),
    changeDocumentStatus: (document, id) =>
      dispatch(changeDocumentStatus(document, id)),
    getAuthData: () => dispatch(getAuthData()),
    withdrawDocument: (document, id) =>
      dispatch(withdrawDocument(document, id)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
    switchDocsListWindows: (componentToGenerate) => {
      return dispatch(docs.switchDocsListWindows(componentToGenerate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsView);
