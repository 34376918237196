const filterNames = {
  RECIEVED_DOCUMENTS: "RECIEVED_DOCUMENTS",
  SENDED_DOCUMENTS: "SENDED_DOCUMENTS",
  DRAFT_DOCUMENTS: "DRAFT_DOCUMENTS",
  ALL_DOCUMENTS: "ALL_DOCUMENTS",
  AWAITING_FOR_OTHERS: "AWAITING_FOR_OTHERS",
  AWAITING_FOR_SENDING: "AWAITING_FOR_SENDING",
  WITHDRAWED_DOCUMENTS: "WITHDRAWED_DOCUMENTS",
  SIGNED_DOCUMENTS: "SIGNED_DOCUMENTS",
  REJECTED_DOCUMENTS: "REJECTED_DOCUMENTS",
};

export default filterNames;
