import React, { Component } from "react";
import { connect } from "react-redux";

import { menu } from "../../../_store/_actions";

import { UikNavBurger, UikTopBarSection } from "../../../@uik";

class AppBurgerMenu extends Component {
  state = {
    isMenuOpen: this.props.isMenuOpen,
  };

  toggleBurgerMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });

    this.props.menu(this.state.isMenuOpen);
  };
  render() {
    return (
      <UikTopBarSection className="burger-menu-section">
        <div className="admin-burger-menu">
          <UikNavBurger
            isOpen={this.props.isMenuOpen}
            onClick={this.toggleBurgerMenu}
          />
        </div>
      </UikTopBarSection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMenuOpen: state.menu.isMenuOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    menu: (isMenuOpen) => {
      return dispatch(menu.menu(isMenuOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBurgerMenu);
