import React, { Component } from "react";
import { connect } from "react-redux";
import { getAuthData } from "../_store/_actions/services/getAuthData";
import AppBurgerMenu from "./components/global/AppBurgerMenu";
import AppNavigation from "./components/global/AppNavigation";
import AppLoggedUser from "./components/global/AppLoggedUser";
import verificateDocument from "../_store/_actions/services/verificatieDocumentService";
import {
  UikButton,
  UikContainerVertical,
  UikContainerHorizontal,
  UikLayoutMain,
  UikInput,
  UikTopBar,
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
} from "../@uik";
import "../@uik/styles.css";
import "../App/styles/verificationPage.css";
export class verificationPage extends Component {
  state = {
    docfile: {},
    isDocumentLoaded: false,
  };

  componentDidMount() {
    this.props.getAuthData();
  }

  handleDocumentChange = (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    this.setState({
      docfile: formData,
      isDocumentLoaded: true,
    });
  };
  handleDocumentVerification = () => {
    this.props.verificateDocument(this.state.docfile);
  };
  render() {
    const { isDocumentLoaded } = this.state;
    return (
      <div className="page-wrap">
        <UikContainerHorizontal>
          {this.props.isMenuOpen ? <AppNavigation /> : null}
          <UikContainerVertical>
            <UikTopBar className="application-top-bar">
              <AppBurgerMenu />
              <AppLoggedUser />
            </UikTopBar>

            <UikLayoutMain>
              <UikWidget className="profile-content">
                <UikWidgetHeader
                  rightEl={
                    isDocumentLoaded ? (
                      <UikButton
                        className="profile-btn"
                        primary
                        form="user-profile-form"
                        onClick={this.handleDocumentVerification}
                      >
                        Check document
                      </UikButton>
                    ) : null
                  }
                >
                  Documents verificator
                </UikWidgetHeader>
                <UikWidgetContent>
                  <UikInput
                    className="add-image-input"
                    type="file"
                    accept="application/PDF"
                    onChange={this.handleDocumentChange}
                  />
                </UikWidgetContent>
              </UikWidget>
              {this.props.isDocumentVerificated === true && (
                <UikWidget>
                  <UikWidgetContent>
                    <h2>
                      This document is
                      <h2 className="verificationPage__positive__message">
                        signed
                      </h2>
                      on MATTERS eSignatures!
                    </h2>
                  </UikWidgetContent>
                </UikWidget>
              )}
              {this.props.isDocumentVerificated === false && (
                <UikWidget>
                  <UikWidgetContent>
                    <h2>
                      This document is
                      <h2 className="verificationPage__negative__message">
                        not signed
                      </h2>
                      on MATTERS eSignatures!
                    </h2>
                  </UikWidgetContent>
                </UikWidget>
              )}
            </UikLayoutMain>
          </UikContainerVertical>
        </UikContainerHorizontal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDocumentVerificated: state.verificate.isDocumentVerificated,
    isMenuOpen: state.menu.isMenuOpen,
    authData: state.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAuthData: () => dispatch(getAuthData()),
    verificateDocument: (document) => dispatch(verificateDocument(document)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(verificationPage);
