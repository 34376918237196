import React, { Component } from "react";

import { connect } from "react-redux";

import { users } from "../../../_store/_actions";

import avatar_default from "../../img/avatar_default.jpg";

import {
  UikButton,
  UikDivider,
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikNavLink,
  UikWidgetContent,
  UikWidgetHeader,
} from "../../../@uik";

class UsersAdd extends Component {
  state = {
    avatar: avatar_default,
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    position: "",
    role: "",
    componentToGenerate: "user-list",
    inputMessages: null,
    refreshRequired: false,
  };

  handleChangeWindow = (e) => {
    this.props.switchUsersListWindows(this.state.componentToGenerate);
  };

  handleAvatarChange = (e) => {
    this.setState({
      avatar: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleUsernameChange = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleFirstNameChange = (e) => {
    this.setState({
      first_name: e.target.value,
    });
  };

  handleLastNameChange = (e) => {
    this.setState({
      last_name: e.target.value,
    });
  };

  handlePositionChange = (e) => {
    this.setState({
      position: e.target.value,
    });
  };

  handleAccountRoleChange = (e) => {
    this.setState({
      role: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.addNewUser(
      this.state.username,
      this.state.password,
      this.state.email,
      this.state.first_name,
      this.state.last_name
    );

    this.setState({
      avatar: avatar_default,
      username: "",
      password: "",
      email: "",
      first_name: "",
      last_name: "",
      position: "",
      role: "",
      componentToGenerate: "user-list",
      refreshRequired: false,
    });
  };

  render() {
    return (
      <>
        <UikNavLink
          onClick={this.handleChangeWindow}
          className="active backlink"
        >
          <Uikon>arrow_left</Uikon>Return to user list
        </UikNavLink>
        <UikWidgetHeader
          rightEl={
            <UikButton primary type="submit" form="add-user-form">
              Save
            </UikButton>
          }
        >
          <div>
            Add users
            {this.props.errorMessage ? <UikDivider /> : null}
            <span>{this.props.errorMessage}</span>
          </div>
        </UikWidgetHeader>
        <UikWidgetContent>
          <form id="add-user-form" onSubmit={this.handleSubmit}>
            <UikFormInputGroup direction="horizontal">
              <div className="add-image-input-wrap">
                <h3>User's image</h3>
                <label htmlFor="add-image-input">
                  <img src={this.state.avatar} alt="add user's avatar" />
                </label>
                <UikInput
                  className="add-image-input"
                  type="file"
                  onChange={this.handleAvatarChange}
                />
              </div>

              <UikFormInputGroup>
                <h3>User's personal data</h3>
                <UikInput
                  className="user-form username-input"
                  label="Username"
                  type="text"
                  value={this.state.username}
                  onChange={this.handleUsernameChange}
                  errorMessage={this.props.inputMessages.username}
                />

                <UikInput
                  className="user-form email-input"
                  label="Email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  errorMessage={this.props.inputMessages.email}
                />
                <UikInput
                  className="user-form first-name-input"
                  label="First name"
                  type="text"
                  value={this.state.first_name}
                  onChange={this.handleFirstNameChange}
                  errorMessage={this.props.inputMessages.first_name}
                />
                <UikInput
                  className="user-form last-name-input"
                  label="Last name"
                  type="text"
                  value={this.state.last_name}
                  onChange={this.handleLastNameChange}
                  errorMessage={this.props.inputMessages.last_name}
                />
                <UikInput
                  className="user-form position-input"
                  label="Position"
                  type="text"
                  value={this.state.position}
                  onChange={this.handlePositionChange}
                />

                <h3>Account's options</h3>
                <UikInput
                  className="user-form account-role"
                  label="Role"
                  type="text"
                  value={this.state.role}
                  onChange={this.handleAccountRoleChange}
                />
                <UikInput
                  className="user-form password-input"
                  label="Password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  errorMessage={this.props.inputMessages.password}
                />
              </UikFormInputGroup>
            </UikFormInputGroup>
          </form>
        </UikWidgetContent>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentToGenerate: state.users.componentToGenerate,
    inputMessages: state.users.inputMessages,
    errorMessage: state.users.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchUsersListWindows: (componentToGenerate) => {
      return dispatch(users.switchUsersListWindows(componentToGenerate));
    },
    addNewUser: (username, password, email, first_name, last_name) => {
      return dispatch(
        users.addNewUser(username, password, email, first_name, last_name)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdd);
