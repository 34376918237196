import address from "../../../_config/address";
import fetch from "../../../fetch";

const { API_URL, USERS } = address;

const fetchUserList = (dispatchUserlistLoaded) => {
  const options = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "Token " + localStorage.getItem("token"),
    },
  };

  fetch(`${API_URL}${USERS}`, options)
    .then((res) => {
      return res.json();
    })
    .then((users) => {
      return dispatchUserlistLoaded(users);
    });
};

export default fetchUserList;
