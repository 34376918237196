import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getEmail } from "../../../_store/_actions/services/getMeService";
import { remind } from "../../../_store/_actions";
import {
  changePasswordNew,
  confirmRegistration,
} from "../../../_store/_actions/remind";
import {
  UikButton,
  UikFormInputGroup,
  UikHeadline,
  UikInput,
  Uikon,
  UikWidget,
  UikWidgetBottomCta,
  UikWidgetContent,
} from "../../../@uik";
import AppLogo from "../../img/matters-esignatures-black.png";
class RemindPassEmail extends Component {
  state = {
    password: "",
    token: "",
    passwordInputError: "",
    showPassword: false,
    hasClicked: false,
    phase: "presend",
    repeatPassword: "",
    repeatPasswordError: "",
  };

  getQueryVariable(tokenToReset) {
    let changePassQuery = window.location.search.substring(1);
    let changePassQueryVariables = changePassQuery.split("&");
    for (let i = 0; i < changePassQueryVariables.length; i++) {
      let pair = changePassQueryVariables[i].split("=");
      if (pair[0] === tokenToReset) {
        return pair[1];
      }
      return false;
    }
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    this.setState({
      token,
    });
    this.props.getEmail(token);
  }

  handlePassChange = (e) => {
    this.setState({
      password: e.target.value,
      repeatPassword: e.target.value,
    });
  };

  handlePassVisibility = (e) => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handlePassSubmit = async (e) => {
    e.preventDefault();
    if (this.state.password === "" && this.state.repeatPassword === "") {
      this.setState({
        passwordInputError: "Required",
        repeatPasswordError: "Required",
      });
    }
    if (this.state.password === "") {
      this.setState({
        passwordInputError: "Required",
      });
    } else if (this.state.repeatPassword === "") {
      this.setState({
        repeatPasswordError: "Required",
      });
    } else if (this.state.password !== this.state.repeatPassword) {
      this.setState({
        passwordInputError: "Password doesn't match",
        repeatPasswordError: "Password doesn't match",
      });
    } else if (this.state.password.length < 8) {
      this.setState({
        passwordInputError: "Password must contain at least 8 characters",
      });
    } else if (this.state.repeatPassword.length < 8) {
      this.setState({
        repeatPasswordError: "Password must contain at least 8 characters",
      });
    } else {
      await this.props.changePasswordNew(this.state.password, this.state.token);
      this.setState({
        phase: "changed",
      });
    }
  };

  handleRepeatPassChange = (e) => {
    this.setState({
      repeatPassword: e.target.value,
    });
  };

  getRemindPassBody = (phase) => {
    switch (phase) {
      case "presend":
        return (
          <div>
            <div className="login-wrap">
              <UikWidget padding>
                <img className="login__page__image__container" src={AppLogo} />
                <UikWidgetContent>
                  <form onSubmit={this.handlePassSubmit}>
                    <UikFormInputGroup>
                      <UikInput
                        className="password-input"
                        label="Email"
                        type={this.state.showPassword ? "email" : "email"}
                        readOnly
                        value={this.props?.email}
                      />
                      <UikInput
                        className="Password-input"
                        label="Password"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handlePassChange}
                        icon={
                          <Uikon
                            className="visibility-icon"
                            onClick={this.handlePassVisibility}
                          >
                            view_simple
                          </Uikon>
                        }
                        iconPosition="right"
                        errorMessage={this.state.repeatPasswordError}
                      />
                      <UikButton primary lg type="submit">
                        Activate account
                      </UikButton>
                    </UikFormInputGroup>
                  </form>
                </UikWidgetContent>

                <UikWidgetBottomCta>
                  <p>{this.props.errorMessage}</p>
                </UikWidgetBottomCta>
              </UikWidget>
            </div>
          </div>
        );
      case "changed":
        return (
          <div>
            <div className="login-wrap">
              <UikWidget padding>
                <UikWidgetContent>
                  <h2>Password was successfuly changed</h2>
                  <UikWidgetBottomCta Component={Link} to="/">
                    <span style={{ color: "#1665D8" }}>Login to MATTERS eSignatures</span>
                  </UikWidgetBottomCta>
                </UikWidgetContent>
              </UikWidget>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  render() {
    return this.getRemindPassBody(this.state.phase);
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.remind.errorMessage,
    passChanged: state.remind.passChanged,
    emailToReset: state.remind.email,
    email: state.users.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmail: (token) => dispatch(getEmail(token)),
    confirmRegistration: (token) => dispatch(confirmRegistration(token)),
    changePasswordNew: (password, token) =>
      dispatch(changePasswordNew(password, token)),
    remindChangePassword: (password, token) => {
      return dispatch(remind.remindChangePassword(password, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindPassEmail);
