import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import address from "../../../_config/address";
import "../../../App/styles/DocsDetails.css";
import {
  UikCheckbox,
  UikWidget,
  UikButton,
  UikWidgetContent,
  UikWidgetHeader,
  UikContainerHorizontal,
} from "../../../@uik";
import AppLogo from "../../img/matters-esignatures-white.svg";
import { getAuthDataWithToken } from "../../../_store/_actions/services/getAuthData";
import {
  getDocumentsWithToken,
  signDocument,
  withdrawCurrentDocument,
  getAttachment,
  getCurrentDocument,
  IsSeenDocumentWithToken,
  signDocumentWithToken,
  documentRejectWithToken,
  documentHistoryDownload,
  resetCurrentDocument,
} from "../../../_store/_actions/services/documentServices";
const { API_URL, ATTACHMENTS } = address;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;
class DocDetailsEmailed extends Component {
  state = {
    isModalOpen: false,
    attachments: "",
    docdate: "",

    docrecipient: [],
    componentToGenerate: "docs-list",
    refreshRequired: false,
    docstatus: "",
    assignations: [],
    status: "",
    name: "",
    isDocumentSigned: false,
    token: localStorage.getItem("token"),
    confirm: false,
    isSignedOpen: true,
    isDetailsOpen: false,
    discarded_at: "",
    created_on: "",
    currentUserHasSigned: "",
    currentUser: "",
    history: [],
    numPages: "",
  };

  componentDidMount() {
    const { DocumentId, token } = this.props.match.params;

    this.props.IsSeenDocumentWithToken(DocumentId, token);
    this.props.getAuthDataWithToken(token);
    this.props.getCurrentDocument(DocumentId, token);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentDocument } = this.props;

    if (currentDocument && this.state.name === "") {
      this.setState({
        ...this.state,
        name: currentDocument.name,
        status: currentDocument.status,
        attachments: [currentDocument.attachments[0]],

        docsenderSecondName: currentDocument.created_by.last_name,
        created_on: new Date(currentDocument.created_at).toLocaleString(),

        assignations: [...this.state.assignations].concat(
          currentDocument.assignations
        ),
        history: [...this.state.history].concat(currentDocument.history),
      });
    }

    const currentUser = this.state.assignations.find(
      (user) => user.user_id === this.props.authData.id
    );
    if (
      currentUser?.status === "SIGNED" &&
      this.state.currentUserHasSigned === ""
    ) {
      this.setState({
        currentUserHasSigned: true,
      });
    }
    if (currentUser?.status === "SENT" && this.state.currentUser === "") {
      this.setState({
        currentUser: true,
      });
    }

    if (prevProps.currentDocument !== this.props.currentDocument) {
      this.setState({
        history: currentDocument.history,
        assignations: currentDocument.assignations,
      });
    }
  }
  componentWillUnmount() {
    this.setState({
      attachments: "",
    });
    this.props.resetCurrentDocument();
  }

  handleDocumentSign = async (id, token) => {
    if (this.state.confirm) {
      await this.props.signDocumentWithToken(id, token);
    }
    this.props.getCurrentDocument(id, token);
    this.setState({
      docstatus: "SIGNED",
      currentUserHasSigned: true,
    });
  };
  handleConfirmChange = () => {
    this.setState({
      confirm: !this.state.confirm,
    });
  };
  handleSignOpen = () => {
    this.setState({
      isSignedOpen: true,
      isDetailsOpen: false,
      isHistoryOpen: false,
    });
  };

  handleDetailsOpen = () => {
    this.setState({
      isSignedOpen: false,
      isDetailsOpen: true,
      isHistoryOpen: false,
    });
  };

  handleHistoryOpen = () => {
    this.setState({
      isSignedOpen: false,
      isDetailsOpen: false,
      isHistoryOpen: true,
    });
  };
  getDocumentReciepents = () => {
    return this.state.assignations.map((reciepent) => {
      return (
        <div
          className="document__details__reciepent__container"
          key={reciepent.email}
        >
          <div className="reciepent__name__container">
            <h5>
              {reciepent.first_name} {reciepent.last_name} <br></br>{" "}
              {reciepent.position} <br></br> {reciepent.company} <br></br>{" "}
              {reciepent.email}
            </h5>
          </div>

          {reciepent.is_seen === true ? (
            <div className="document__details__reciepent__vertical__container">
              <h5 className="reciepent__name__last__name">
                Viewed: {new Date(reciepent.seen_at).toLocaleString()}
              </h5>
              <br></br>
              <h5 className="reciepent__status">Status: {reciepent.status}</h5>
            </div>
          ) : (
            <div className="document__details__reciepent__vertical__container">
              <h5 className="document__details__reciepent__has__seen">
                Not viewed
              </h5>
              <h5 className="reciepent__sttus">Status: {reciepent.status}</h5>
            </div>
          )}
        </div>
      );
    });
  };
  getDocumentHistorySwitchHandler = (action) => {
    switch (action) {
      case "RECIPIENT_SEEN":
        return "Seen by";
      case "DOCUMENT_CREATED":
        return "Document created by";
      case "DOCUMENT_SIGNED":
        return "Signed and sealed by";
      case "SENT_TO_RECIPIENT":
        return "Sent to recipents by";
      case "RECIPIENT_SIGN":
        return "Document signed by";
      case "RECIPIENT_REJECT":
        return "Rejected by";
      case "DOCUMENT_DOWNLOAD":
        return "Download by";
      case "DOCUMENT_WITHDRAWN":
        return "Withdrawn by";
      default:
        break;
    }
  };

  getDocumentHistory = () => {
    return this.state.history.map((history) => {
      return (
        <div className="document__details__history__container">
          <div className="document__details__history__action__container">
            <h5>
              {this.getDocumentHistorySwitchHandler(history.event_name)}{" "}
              {history.created_by.first_name} {history.created_by.last_name} (
              {history.created_by.email})
            </h5>

            {history.ip_address}

            <h5 className="document__details__history__action__done__at">
              {new Date(history.created_at).toLocaleString()}
            </h5>
          </div>
        </div>
      );
    });
  };

  handleDocumentDiscard = async (document, id, token) => {
    await this.props.documentRejectWithToken(document, id, token);
    this.props.getCurrentDocument(id, token);
    this.setState({
      docstatus: "REJECTED",
      discarded_at: new Date(),
    });
  };
  handleDocumentWithdrawal = (document, id, token) => {
    this.props.documentRejectWithToken(document, id, token);
  };

  handleDocumentDownload = () => {
    const { DocumentId, token } = this.props.match.params;
    this.props.documentHistoryDownload(DocumentId);
    window.open(
      `${API_URL}${ATTACHMENTS}/${this.state.attachments}/download/?token=${token}}`
    );
  };

  renderPdfPages = () => {
    const pages = [];
    for (let i = 1; i <= this.state.numPages; i++) {
      pages.push(<Page pageNumber={i} />);
    }
    return pages;
  };

  handleAttachmentClear = () => {
    this.setState({
      attachments: "",
    });
  };

  render() {
    const docStatus =
      this.state.docstate || this.props?.currentDocument?.status;
    const { DocumentId, token } = this.props.match.params;
    return (
      <React.Fragment>
        <UikWidget>
          <div className="document__details__widget__header">
            <img className="document__details__logo__container" src={AppLogo} />
            {this.props.authData.groups[0] !== 1 && (
              <Link
                to="/docs"
                onClick={this.handleAttachmentClear}
                className="document__details__exit_button"
              >
                x
              </Link>
            )}
          </div>
          <UikContainerHorizontal>
            <UikWidgetContent className="container__horizontal__half__document">
              <div className="document__scrollbar__wrapper">
                <div className="document__name__container">
                  {this.props?.currentDocument?.name}{" "}
                  <p
                    className="download__document__paragraph__button"
                    onClick={this.handleDocumentDownload}
                  >
                    Download document
                  </p>
                </div>
                <Document
                  onLoadSuccess={(pdf) => {
                    if (this.state.numPages === "") {
                      this.setState({
                        ...this.state,
                        numPages: pdf.numPages,
                      });
                    }
                  }}
                  className="document__canvas"
                  file={{
                    url: `${API_URL}${ATTACHMENTS}/${this.state.attachments}/download/`,
                    httpHeaders: {
                      Authorization: "Token " + token,
                    },
                  }}
                  renderMode="canvas"
                >
                  {this.state.numPages && this.renderPdfPages()}
                </Document>
              </div>
            </UikWidgetContent>
            {docStatus !== "REJECTED" ? (
              <UikWidgetContent className="container__horizontal__half">
                <div className="tab__navigation__container">
                  {this.state.isSignedOpen === true ? (
                    <span
                      onClick={this.handleSignOpen}
                      className="span__active "
                    >
                      Signing
                    </span>
                  ) : (
                    <span
                      onClick={this.handleSignOpen}
                      className="span__deactived"
                    >
                      Signing
                    </span>
                  )}
                  {this.state.isDetailsOpen === true ? (
                    <span
                      onClick={this.handleDetailsOpen}
                      className="span__active "
                    >
                      Details
                    </span>
                  ) : (
                    <span
                      onClick={this.handleDetailsOpen}
                      className="span__deactived"
                    >
                      Details
                    </span>
                  )}
                  {this.state.isHistoryOpen === true ? (
                    <span
                      onClick={this.handleHistoryOpen}
                      className="span__active"
                    >
                      History
                    </span>
                  ) : (
                    <span
                      onClick={this.handleHistoryOpen}
                      className="span__deactived"
                    >
                      History
                    </span>
                  )}
                </div>
                {this.state.isSignedOpen && (
                  <React.Fragment>
                    <div className="document__sender__details__container">
                      <h2>Sender</h2>
                      {this.props?.currentDocument?.created_by?.first_name}{" "}
                      {this.props?.currentDocument?.created_by?.last_name}
                      <br></br>
                      {this.props?.currentDocument?.created_by?.position}
                      <br></br>
                      {this.props?.currentDocument?.created_by?.company}
                      <br></br>
                      {this.props?.currentDocument?.created_by?.email}
                    </div>
                    {this.state.currentUserHasSigned !== true ? (
                      <React.Fragment>
                        <div className="document__widget__signing__information">
                          <h5>
                            Please verify your details before signing the
                            document.
                          </h5>
                        </div>
                        <h2 className="document__widget__h2__title ">
                          Your details
                        </h2>
                        <p>
                          Sender asks you to sign the document, please confirm
                          your data
                        </p>
                        <div className="document__details__horizontal__container">
                          <h5>
                            e-mail: <b>{this.props.authData.email}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            name: <b>{this.props.authData.first_name}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            surname: <b>{this.props.authData.last_name}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            company:{" "}
                            <b>{this.props.authData.profile.company}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            position:{" "}
                            <b>{this.props.authData.profile.position}</b>
                          </h5>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="document__signed__status__container">
                          <p className="document__signed__status__p">
                            Document is signed by you{" "}
                          </p>
                        </div>
                        <h2 className="document__widget__h2__title ">
                          Your details
                        </h2>

                        <div className="document__details__horizontal__container">
                          <h5>
                            e-mail: <b>{this.props.authData.email}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            name: <b>{this.props.authData.first_name}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            surname: <b>{this.props.authData.last_name}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            comapny:{" "}
                            <b>{this.props.authData.profile.company}</b>
                          </h5>
                        </div>
                        <div className="document__details__horizontal__container">
                          <h5>
                            position:{" "}
                            <b>{this.props.authData.profile.position}</b>
                          </h5>
                        </div>
                      </React.Fragment>
                    )}

                    {docStatus === "SENT" &&
                      this.state.currentUserHasSigned === "" &&
                      this.state.currentUser !== "" && (
                        <React.Fragment>
                          <h2 className="document__widget__h2__title ">
                            Declarations and consent
                          </h2>

                          <UikCheckbox
                            className="login-form stay-logged-checkbox"
                            color="blue"
                            label="
                   I declare that I know and accept the content in the indicated files."
                            checked={this.state.confirm}
                            onChange={this.handleConfirmChange}
                          />
                        </React.Fragment>
                      )}

                    {docStatus !== "SENT" ||
                    this.state.currentUserHasSigned === true ||
                    this.state.currentUser === "" ? null : (
                      <div className="sign__buttons__container">
                        {this.state.confirm === true ? (
                          <UikButton
                            className="sign__button__primary"
                            success
                            onClick={() =>
                              this.handleDocumentSign(DocumentId, token)
                            }
                          >
                            Sign
                          </UikButton>
                        ) : (
                          <UikButton className="sign__button__primary" disabled>
                            Sign
                          </UikButton>
                        )}

                        <UikButton
                          className="sign__button__secondary"
                          transparent
                          onClick={() =>
                            this.handleDocumentDiscard(
                              this.state,
                              DocumentId,
                              token
                            )
                          }
                        >
                          Reject
                        </UikButton>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {this.state.isDetailsOpen && (
                  <React.Fragment>
                    <div className="document__sender__details__container">
                      <h2>Sender</h2>
                      {this.props?.currentDocument?.created_by?.first_name}{" "}
                      {this.props?.currentDocument?.created_by?.last_name}
                      <br></br>
                      {this.props?.currentDocument?.created_by?.position}
                      <br></br>
                      {this.props?.currentDocument?.created_by?.company}
                      <br></br>
                      {this.props?.currentDocument?.created_by?.email}
                    </div>

                    <h2 className="document__widget__h2__title">
                      Document details
                    </h2>
                    <div className="document__details__horizontal__container">
                      <h5>
                        Name: <b>{this.props?.currentDocument?.name}</b>
                      </h5>
                    </div>

                    <div className="document__details__horizontal__container">
                      <h5>
                        Created: <b>{this.state.created_on}</b>
                      </h5>
                    </div>

                    <div className="document__details__horizontal__container">
                      <h5>
                        Status: <b>{docStatus}</b>
                      </h5>
                    </div>

                    <h2 className="document__widget__h2__title">Files</h2>
                    <div className="document__file__details__container ">
                      <p>`{this.state.attachments.name}.pdf`</p>
                      <p
                        className="download__document__paragraph__button"
                        onClick={this.handleDocumentDownload}
                      >
                        Download document
                      </p>
                    </div>

                    <h2 className="document__widget__h2__title">Reciepents</h2>
                    {this.getDocumentReciepents()}
                  </React.Fragment>
                )}
                {this.state.isHistoryOpen && (
                  <React.Fragment>
                    <h2 className="document__widget__h2__title">History</h2>
                    {this.getDocumentHistory()}
                  </React.Fragment>
                )}
              </UikWidgetContent>
            ) : (
              <UikWidgetContent className="container__horizontal__half">
                {this.state.isSignedOpen && (
                  <React.Fragment>
                    <div className="tab__navigation__container">
                      {this.state.isSignedOpen === true ? (
                        <span
                          onClick={this.handleSignOpen}
                          className="span__active "
                        >
                          Signing
                        </span>
                      ) : (
                        <span
                          onClick={this.handleSignOpen}
                          className="span__deactived"
                        >
                          Signing
                        </span>
                      )}

                      {this.state.isHistoryOpen === true ? (
                        <span
                          onClick={this.handleHistoryOpen}
                          className="span__active"
                        >
                          History
                        </span>
                      ) : (
                        <span
                          onClick={this.handleHistoryOpen}
                          className="span__deactived"
                        >
                          History
                        </span>
                      )}
                    </div>
                    <div className="document__sender__details__container">
                      <h2>Sender</h2>
                      {this.props?.currentDocument?.created_by?.first_name}{" "}
                      {this.props?.currentDocument?.created_by?.last_name}
                      <br></br>
                      {this.state.docsenderJobTitle}
                      <br></br>
                      {this.state.docsenderOrganisationName}
                      <br></br>
                      {this.props?.currentDocument?.created_by?.email}
                    </div>
                    <div className="discarded__document__text__container">
                      <h4 className="discarded__text__color">
                        Document was discarded
                      </h4>
                    </div>

                    <h2 className="document__widget__h2__title">
                      Document details
                    </h2>
                    <div className="document__details__horizontal__container">
                      <h5>
                        Name: <b>{this.props?.currentDocument?.name}</b>
                      </h5>
                    </div>

                    <div className="document__details__horizontal__container">
                      <h5>
                        Created: <b>{this.state.created_on}</b>
                      </h5>
                    </div>

                    <div className="document__details__horizontal__container">
                      <h5>
                        Status: <b>{docStatus}</b>
                      </h5>
                    </div>

                    <h2 className="document__widget__h2__title">Files</h2>
                    <div className="document__file__details__container ">
                      <p>{this.props.currentDocumentName}</p>
                      <UikButton onClick={this.handleDocumentDownload}>
                        Download document
                      </UikButton>
                    </div>

                    <h2 className="document__widget__h2__title">Reciepents</h2>
                    {this.getDocumentReciepents()}
                  </React.Fragment>
                )}
                {this.state.isHistoryOpen && (
                  <React.Fragment>
                    <div className="tab__navigation__container">
                      {this.state.isSignedOpen === true ? (
                        <span
                          onClick={this.handleSignOpen}
                          className="span__active "
                        >
                          Signing
                        </span>
                      ) : (
                        <span
                          onClick={this.handleSignOpen}
                          className="span__deactived"
                        >
                          Signing
                        </span>
                      )}

                      {this.state.isHistoryOpen === true ? (
                        <span
                          onClick={this.handleHistoryOpen}
                          className="span__active"
                        >
                          History
                        </span>
                      ) : (
                        <span
                          onClick={this.handleHistoryOpen}
                          className="span__deactived"
                        >
                          History
                        </span>
                      )}
                    </div>
                    <h2 className="document__widget__h2__title">History</h2>
                    {this.getDocumentHistory()}
                  </React.Fragment>
                )}
              </UikWidgetContent>
            )}
          </UikContainerHorizontal>
        </UikWidget>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    currentDocument: state.documents.currentDocument,
    userList: state.users.userList,
    componentToGenerate: state.docs.componentToGenerate,
    errorMessage: state.users.errorMessage,
    documentsList: state.documents.documentsList,
    attachmentUrl: state.documents.attachmentUrl,
    authData: state.authData,
    currentDocumentId: state.documents.currentDocumentId,
    currentDocumentName: state.documents.currentDocumentName,
    isDocumentSigned: state.documents.isDocumentSigned,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCurrentDocument: () => dispatch(resetCurrentDocument()),
    documentHistoryDownload: (id) => dispatch(documentHistoryDownload(id)),
    documentRejectWithToken: (document, id, token) =>
      dispatch(documentRejectWithToken(document, id, token)),
    signDocumentWithToken: (id, token) =>
      dispatch(signDocumentWithToken(id, token)),
    IsSeenDocumentWithToken: (id, token) =>
      dispatch(IsSeenDocumentWithToken(id, token)),
    getAuthDataWithToken: (token) => dispatch(getAuthDataWithToken(token)),
    getCurrentDocument: (id, token) => dispatch(getCurrentDocument(id, token)),
    getDocumentsWithToken: (token) => dispatch(getDocumentsWithToken(token)),
    getAttachment: (id) => dispatch(getAttachment(id)),
    withdrawCurrentDocument: (document, id, token) =>
      dispatch(withdrawCurrentDocument(document, id, token)),
    signDocument: (id) => dispatch(signDocument(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocDetailsEmailed);
