import React, { Component } from "react";
import { connect } from "react-redux";

import { docs } from "../../../_store/_actions";
import {
  getDocuments,
  IsSeenDocument,
} from "../../../_store/_actions/services/documentServices";
import {
  UikButton,
  UikSelect,
  UikTag,
  UikWidgetContent,
  UikWidgetHeader,
  UikWidgetTable,
  UikButtonGroup,
} from "../../../@uik";
import { getOrganisation } from "../../../_store/_actions/services/organizationServices";
import { getAuthData } from "../../../_store/_actions/services/getAuthData";
import docsFilterService from "../../../../src/services/docsFilterService";
import filterNames from "../../../constans/filterNames";
import DocumentsDropdownMenu from "../docs/documentsDropdownMenu";
const {
  RECIEVED_DOCUMENTS,
  SENDED_DOCUMENTS,
  DRAFT_DOCUMENTS,
  ALL_DOCUMENTS,
  WITHDRAWED_DOCUMENTS,
  SIGNED_DOCUMENTS,
  REJECTED_DOCUMENTS,
} = filterNames;
export const filterOptions = [
  {
    value: DRAFT_DOCUMENTS,
    label: "Filters: Draft",
  },
  {
    value: SENDED_DOCUMENTS,
    label: "Filters: Sended",
  },

  {
    value: ALL_DOCUMENTS,
    label: "Filters: All",
  },
  {
    value: WITHDRAWED_DOCUMENTS,

    label: "Filters: Withdrawed",
  },
  {
    value: SIGNED_DOCUMENTS,

    label: "Filters: Signed",
  },
  {
    value: REJECTED_DOCUMENTS,

    label: "Filters: Rejected",
  },
];

class DocsTable extends Component {
  state = {
    componentToGenerate: "docs-add",
    openAccountOptions: false,
    documents: [],
    filter: "",
    renderFlag: false,
    documentOptions: false,
    componentToPreview: "docs-view",
    componentToEdit: "docs-edit",
  };
  componentDidMount() {
    this.props.getDocuments();
    this.setState({
      renderFlag: true,
    });
    this.props.getAuthData();
    this.props.getOrganisation();
  }

  componentDidUpdate() {
    if (this.state.renderFlag === true) {
      this.props.getDocuments();
      this.props.getAuthData();
      this.setState({
        documents: this.props.documentsList,
        renderFlag: false,
      });
    }
  }
  toggleDocumentOptions = () => {
    this.setState({
      documentOptions: !this.state.documentOptions,
    });
  };
  handleChangeWindow = () => {
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };

  handlePreviewDocument = (id) => {
    this.props.switchDocsListWindows(this.state.componentToPreview, id);
    this.props.onItemSelected(id);
    this.props.IsSeenDocument(id);
  };

  handleReadDocument = (id) => {
    this.props.switchDocsListWindows(this.state.componentToEdit, id);
    this.props.onItemSelected(id);
    this.props.IsSeenDocument(id);
  };

  handleDocsDetails = (id) => {
    this.props.switchDocsListWindows("docs-view");
    this.props.onItemSelected(id);
    this.props.IsSeenDocument(id);
  };
  handleSelectChange = (e) => {
    this.setState({
      filter: e.value,
    });
  };
  renderDocsList() {
    return (
      <>
        {this.props.documentsList &&
          docsFilterService(this.props.documentsList, this.state.filter)?.map(
            (document) => {
              return (
                <React.Fragment key={document.id}>
                  <tr className="document-list-row" key={document.id}>
                    <td>{document.name}</td>
                    <td>{document.created_by.email}</td>
                    <td>{new Date(document.created_at).toLocaleString()}</td>
                    <td>
                      <UikTag>{document.status}</UikTag>
                    </td>
                    <td>
                      <div className="documentList-dropdown">
                        <UikButton
                          className="documentList-opts-trigger"
                          onClick={this.toggleDocumentOptions}
                        >
                          ...
                        </UikButton>
                        {this.state.documentOptions ? (
                          <div className="documentList-opts-container">
                            <DocumentsDropdownMenu
                              readDocument={this.handleReadDocument}
                              previewDocument={this.handlePreviewDocument}
                              id={document.id}
                              status={document.status}
                            />
                          </div>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            }
          )}
      </>
    );
  }

  render() {
    return (
      <>
        <UikWidgetHeader
          rightEl={
            <>
              <UikButtonGroup>
                <UikSelect
                  className="docs-list-filter"
                  placeholder="Filters: all"
                  options={filterOptions}
                  onChange={this.handleSelectChange}
                />

                <UikButton
                  className="docs-add-btn"
                  onClick={this.handleChangeWindow}
                  primary
                >
                  Add document
                </UikButton>
              </UikButtonGroup>
            </>
          }
        >
          Documents
        </UikWidgetHeader>
        <UikWidgetContent>
          <UikWidgetTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Creator</th>
                <th>Date</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>{this.renderDocsList()}</tbody>
          </UikWidgetTable>
        </UikWidgetContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    status: state.documents.status,
    documentsList: state.documents.documentsList,
    componentToGenerate: state.docs.componentToGenerate,
    authData: state.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrganisation: () => dispatch(getOrganisation()),
    IsSeenDocument: (id) => dispatch(IsSeenDocument(id)),
    getAuthData: () => dispatch(getAuthData()),
    getDocuments: () => dispatch(getDocuments()),
    switchDocsListWindows: (componentToGenerate, id) => {
      return dispatch(docs.switchDocsListWindows(componentToGenerate, id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsTable);
