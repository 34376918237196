import React, { Component } from "react";

import { connect } from "react-redux";

import { docs } from "../../../_store/_actions";

import {
  UikButton,
  UikButtonGroup,
  UikDivider,
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikNavLink,
  UikWidgetContent,
  UikWidgetHeader,
} from "../../../@uik";

class DocsNewReceipent extends Component {
  state = {
    email: "",
    role: { physical: true, organisation: false },
    name: "",
    surname: "",
    position: "",
    organisation: "",
    permissions: { sign: true, previewOnly: false },
    componentToGenerate: "docs-list",
    refreshRequired: false,
  };

  handleChangeWindow = (e) => {
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleRolePhysChange = (e) => {
    this.setState({
      role: {
        physical: e.target.checked,
        organisation: !e.target.checked,
      },
      position: "",
      organisation: "",
    });
  };

  handleRoleOrgChange = (e) => {
    this.setState({
      role: {
        physical: !e.target.checked,
        organisation: e.target.checked,
      },
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleSurnameChange = (e) => {
    this.setState({
      surname: e.target.value,
    });
  };

  handlePositionChange = (e) => {
    this.setState({
      position: e.target.value,
    });
  };

  handleOrganisationChange = (e) => {
    this.setState({
      organisation: e.target.value,
    });
  };

  handlePermSignChange = (e) => {
    this.setState({
      permissions: {
        sign: e.target.checked,
        previewOnly: !e.target.checked,
      },
    });
  };

  handlePermPreviewOnlyChange = (e) => {
    this.setState({
      permissions: {
        sign: !e.target.checked,
        previewOnly: e.target.checked,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      email: "",
      role: { physical: true, organisation: false },
      name: "",
      surname: "",
      position: "",
      organisation: "",
      permissions: { sign: true, previewOnly: false },
      componentToGenerate: "docs-list",
      refreshRequired: false,
    });
  };

  render() {
    return (
      <>
        <UikNavLink
          onClick={this.handleChangeWindow}
          className="active backlink"
        >
          <Uikon>arrow_left</Uikon>Return to documents list
        </UikNavLink>
        <UikWidgetHeader>
          <div>
            New receipent
            {this.props.errorMessage ? <UikDivider /> : null}
            <span>{this.props.errorMessage}</span>
          </div>
        </UikWidgetHeader>
        <UikWidgetContent>
          <form id="add-rec-form" onSubmit={this.handleSubmit}>
            <UikFormInputGroup>
              <UikInput
                className="add-rec-form email-input"
                label="Email"
                type="text"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />

              <h3>Personalia</h3>

              <UikFormInputGroup direction="horizontal">
                <UikInput
                  className="add-rec-form name-input"
                  label="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />

                <UikInput
                  className="add-rec-form surname-input"
                  label="Surname"
                  type="text"
                  value={this.state.surname}
                  onChange={this.handleSurnameChange}
                />
              </UikFormInputGroup>

              <UikFormInputGroup direction="horizontal">
                <>
                  <UikInput
                    className="add-rec-form position-input"
                    label="Position"
                    type="text"
                    value={this.state.position}
                    onChange={this.handlePositionChange}
                  />

                  <UikInput
                    className="add-rec-form organisation-input"
                    label="Organization"
                    placeholder="Enter organization"
                    type="text"
                    value={this.state.organisation}
                    onChange={this.handleOrganisationChange}
                  />
                </>
                )
              </UikFormInputGroup>

              <UikButtonGroup>
                <UikButton primary form="add-rec-form" type="submit">
                  Add receipent
                </UikButton>
                <UikButton onClick={this.handleChangeWindow}>Cancel</UikButton>
              </UikButtonGroup>
            </UikFormInputGroup>
          </form>
        </UikWidgetContent>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentToGenerate: state.docs.componentToGenerate,
    errorMessage: state.users.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchDocsListWindows: (componentToGenerate) => {
      return dispatch(docs.switchDocsListWindows(componentToGenerate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsNewReceipent);
