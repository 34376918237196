import React, { Component } from "react";
import { connect } from "react-redux";
import { UikDropdownItem } from "../../../@uik";
import { docs } from "../../../_store/_actions";
import { Link } from "react-router-dom";
import {
  deleteDocument,
  getDocuments,
  IsSeenDocument,
} from "../../../_store/_actions/services/documentServices";

class DocumentsDropdownMenu extends Component {
  state = {
    id: this.props.id,
    status: this.props.status,
    componentToGenerate: "docs-view",
    token: localStorage.getItem("token"),
  };

  handleViewDocument = (id) => {
    this.props.switchDocsListWindows("docs-preview");
    this.props.onItemSelected(id);
    this.props.IsSeenDocument(id);
  };

  handleChangeWindow = () => {
    this.props.switchDocsListWindows(
      this.state.componentToGenerate,
      this.state.id
    );
  };

  handlePreviewDocument = () => {
    this.props.switchDocsListWindows("docs-view");
  };

  handleDocumentDelete = () => {
    this.props.deleteDocument(this.props.id);
  };

  showDeleteButton = () => {
    return this.props.status === "DRAFT";
  };

  render() {
    return (
      <>
        <UikDropdownItem
          onClick={() => this.props.previewDocument(this.props.id)}
        >
          View
        </UikDropdownItem>
        <UikDropdownItem
          Component={Link}
          to={`/documents/${this.props.id}/sign_preview/${this.props.authData.auth_token}`}
          onClick={() => this.props.readDocument(this.props.id)}
        >
          Read
        </UikDropdownItem>
        {this.showDeleteButton() && (
          <UikDropdownItem onClick={this.handleDocumentDelete}>
            Delete
          </UikDropdownItem>
        )}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    status: state.documents.status,
    ...ownProps,
    documentsList: state.documents.documentsList,
    componentToGenerate: state.docs.componentToGenerate,
    authData: state.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    IsSeenDocument: (id) => dispatch(IsSeenDocument(id)),
    getDocuments: () => dispatch(getDocuments()),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
    switchDocsListWindows: (componentToGenerate) => {
      return dispatch(docs.switchDocsListWindows(componentToGenerate));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsDropdownMenu);
