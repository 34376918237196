import eventStatuses from "../../_config/eventStatuses";

const { VERIFICATE_DOCUMENT } = eventStatuses.verificate;

const initState = {
  isDocumentVerificated: "",
};

const verificationReducer = (state = initState, action) => {
  switch (action.type) {
    case VERIFICATE_DOCUMENT:
      return {
        isDocumentVerificated: action.payload.signed,
      };
    default:
      return state;
  }
};

export default verificationReducer;
