import React, { Component } from "react";
import { connect } from "react-redux";
import { UikWidget, UikWidgetContent, UikContainerVertical } from "../../@uik";
import "../../@uik";

class passwordConfirmationPage extends Component {
  state = {};

  render() {
    return (
      <>
        <UikContainerVertical>
          <UikWidget>
            <UikWidgetContent>
              <h2>Password instruction</h2>
              <br />
              <p>
                In case that account with provided email exists
                <br />
                You will recive an email with instruction
              </p>
            </UikWidgetContent>
          </UikWidget>
        </UikContainerVertical>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(passwordConfirmationPage);
