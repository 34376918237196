import eventStatues from "../../../_config/eventStatuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";
const {
  ADD_DOCUMENT,
  ADD_DOCUMENTS,
  DELETE_DOCUMENT,
  SAVE_DRAFT,
  ADD_ATTACHMENT,
  GET_ATTACHMENT,
  CHANGE_STATUS,
  WITDHRAW_DOCUMENT,
  PASS_ID,
  SIGN_DOCUMENT,
  PASS_DOC_NAME,
  IS_SEEN,
  GET_CURRENT_DOCUMENT,
  REJECT_DOCUMENT,
  CHANGE_MODAL_VIEW,
  RESET_CURRENT_DOCUMENT,
} = eventStatues.documents;
const { API_URL, DOCUMENTS, ATTACHMENTS } = address;
const getOptions = () => ({
  headers: {
    "content-type": "application/json",
    Authorization: "Token " + localStorage.getItem("token"),
  },
});

const deleteOptions = {
  method: "DELETE",
  headers: {
    "content-type": "application/json",
    Authorization: "Token " + localStorage.getItem("token"),
  },
};

export const addDocument = (document) => async (dispatch, getState) => {
  try {
    const documentId = getState().documents;
    const response = await fetch(`${API_URL}${DOCUMENTS}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        ...document,
        attachments: [documentId.docfileId],
        status: "SENT",
      }),
    });
    const dispatchAddDocument = await response.json();

    dispatch({ type: ADD_DOCUMENT, payload: dispatchAddDocument });
  } catch (error) {
    console.log(error);
  }
};

export const getDocuments = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${DOCUMENTS}`, getOptions());
      const documents = await response.json();

      let documentsList = documents.results;
      dispatch({ type: ADD_DOCUMENTS, payload: documentsList });
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteDocument = (id) => {
  return async (dispatch) => {
    try {
      await fetch(`${API_URL}${DOCUMENTS}/${id}`, deleteOptions);
      dispatch({ type: DELETE_DOCUMENT, payload: id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const addDraft = (document) => async (dispatch, getState) => {
  try {
    const documentId = getState().documents;
    const response = await fetch(`${API_URL}${DOCUMENTS}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        ...document,
        attachments: [documentId.docfileId],
        status: "DRAFT",
      }),
    });
    const dispatchAddDraft = await response.json();

    dispatch({ type: SAVE_DRAFT, payload: dispatchAddDraft });
  } catch (error) {
    console.log(error);
  }
};

export const uploadAttachment = (attachment) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${ATTACHMENTS}`, {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: attachment,
    });
    const uploadedFile = await response.json();
    dispatch({
      type: ADD_ATTACHMENT,
      payload: uploadedFile.id,
    });
    return uploadedFile;
  } catch (error) {
    console.log(error);
  }
};

export const changeDocumentStatus = (document, id) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/send/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({ ...document, status: "SENT" }),
    });
    const changedDocument = await response.json();
    dispatch({ type: CHANGE_STATUS, payload: changedDocument });
  } catch (error) {
    console.log(error);
  }
};

export const withdrawDocument = (document, id) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/withdraw/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({ ...document, status: "WITHDRAWED" }),
    });
    const withdrawedDocument = await response.json();
    dispatch({ type: WITDHRAW_DOCUMENT, payload: withdrawedDocument });
  } catch (error) {
    console.log(error);
  }
};

export const signDocument = (id) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/sign/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    const signedDocument = await response.json();
    dispatch({ type: SIGN_DOCUMENT, payload: signedDocument });
  } catch (error) {
    console.log(error);
  }
};

export const getAttachment = (id) => async (dispatch) => {
  try {
    const response = await fetch(
      `${API_URL}${DOCUMENTS}/${ATTACHMENTS}/${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    const attachmentUrl = response.json();

    dispatch({ type: GET_ATTACHMENT, payload: attachmentUrl });
  } catch (error) {
    console.log(error);
  }
};

export const dispatchDocumentId = (id) => (dispatch) => {
  dispatch({ type: PASS_ID, payload: id });
};

export const dispatchDocumentName = (name) => (dispatch) => {
  dispatch({ type: PASS_DOC_NAME, payload: name });
};

export const IsSeenDocument = (id) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/see/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    const isDocumentSeen = response.json();

    dispatch({ type: IS_SEEN, payload: isDocumentSeen });
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentDocument = (id, token) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + token,
      },
    });
    const currentDocument = await response.json();
    dispatch({ type: GET_CURRENT_DOCUMENT, payload: currentDocument });
  } catch (error) {
    console.log(error);
  }
};

export const withdrawCurrentDocument = (document, id, token) => async (
  dispatch
) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/withdraw/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + token,
      },
      body: JSON.stringify({ ...document, status: "WITHDRAWED" }),
    });
    const withdrawedDocument = await response.json();
    dispatch({ type: WITDHRAW_DOCUMENT, payload: withdrawedDocument });
  } catch (error) {
    console.log(error);
  }
};

export const getDocumentsWithToken = (token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${DOCUMENTS}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + token,
        },
      });
      const documents = await response.json();

      let documentsList = documents.results;
      dispatch({ type: ADD_DOCUMENTS, payload: documentsList });
    } catch (error) {
      console.log(error);
    }
  };
};

export const IsSeenDocumentWithToken = (id, token) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/see/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + token,
      },
    });
    const isDocumentSeen = response.json();

    dispatch({ type: IS_SEEN, payload: isDocumentSeen });
  } catch (error) {
    console.log(error);
  }
};

export const seeDocument = (id) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/see/`, {
      method: "PATH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    const isDocumentSeen = response.json();
    dispatch({ type: IS_SEEN, payload: isDocumentSeen });
  } catch (error) {
    console.log(error);
  }
};

export const signDocumentWithToken = (id, token) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/sign/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + token,
      },
    });

    const signedDocument = await response.json();
    dispatch({ type: SIGN_DOCUMENT, payload: signedDocument });
  } catch (error) {
    console.log(error);
  }
};

export const documentReject = (document, id) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/reject/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({ ...document, status: "REJECTED" }),
    });
    const rejectedDocument = await response.json();
    dispatch({ type: REJECT_DOCUMENT, payload: rejectedDocument });
  } catch (error) {
    console.log(error);
  }
};

export const documentRejectWithToken = (document, id, token) => async (
  dispatch
) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${id}/reject/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + token,
      },
      body: JSON.stringify({ ...document, status: "REJECTED" }),
    });
    const rejectedDocument = await response.json();
    dispatch({ type: REJECT_DOCUMENT, payload: rejectedDocument });
  } catch (error) {
    console.log(error);
  }
};

export const documentNewReciepentWindowChange = (value) => async (dispatch) => {
  dispatch({ type: CHANGE_MODAL_VIEW, payload: value });
};

export const documentHistoryDownload = (id) => async (dispatch) => {
  try {
    await fetch(`${API_URL}${DOCUMENTS}/${id}/download_event/`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const resetCurrentDocument = () => async (dispatch) => {
  dispatch({ type: RESET_CURRENT_DOCUMENT, payload: "" });
};
