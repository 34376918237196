import eventStatuses from "../../../_config/eventStatuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";

const { API_URL, VALIDATE, DOCUMENTS } = address;
const { VERIFICATE_DOCUMENT } = eventStatuses.verificate;

const verificateDocument = (document) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${DOCUMENTS}/${VALIDATE}`, {
      method: "POST",

      body: document,
    });

    const verificatedDocument = await response.json();
    dispatch({ type: VERIFICATE_DOCUMENT, payload: verificatedDocument });
  } catch (error) {
    console.log(error);
  }
};

export default verificateDocument;
