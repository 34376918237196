import eventStatuses from "../../_config/eventStatuses";

const {
  ADD_DOCUMENTS,
  DELETE_DOCUMENT,
  SAVE_DRAFT,
  ADD_ATTACHMENT,
  GET_ATTACHMENT,
  CHANGE_STATUS,
  WITDHRAW_DOCUMENT,
  PASS_ID,
  PASS_DOC_NAME,
  SIGN_DOCUMENT,
  IS_SEEN,
  GET_CURRENT_DOCUMENT,
  REJECT_DOCUMENT,
  CHANGE_MODAL_VIEW,
  SEND_DOCUMENT,
  RESET_CURRENT_DOCUMENT,
} = eventStatuses.documents;

const initState = {
  documentsList: [],
  status: "",
  docfileId: "",
  attachmentUrl: "",
  currentDocumentId: "",
  currentDocumentName: "",
  isDocumentSigned: "",
  currentDocument: "",
  currentDocumentDraftId: "",
  is_modal_open: "",
};

const documentsReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_DOCUMENTS:
      return {
        ...state,
        documentsList: action.payload,
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        documentsList: state.documentsList.filter(
          (document) => document.id !== action.payload
        ),
      };
    case SAVE_DRAFT:
      return {
        ...state,
        status: "DRAFT",
        documentsList: [...state.documentsList].concat(action.payload),
        currentDocumentDraftId: action.payload.id,
      };
    case ADD_ATTACHMENT:
      return {
        ...state,
        docfileId: action.payload,
      };
    case GET_ATTACHMENT:
      return {
        ...state,
        attachmentUrl: action.payload,
      };
    case CHANGE_STATUS:
      return {
        ...state,
      };
    default:
      return state;
    case WITDHRAW_DOCUMENT:
      return {
        ...state,
      };
    case REJECT_DOCUMENT:
      return {
        ...state,
      };
    case PASS_ID: {
      return {
        ...state,
        currentDocumentId: action.payload,
      };
    }
    case PASS_DOC_NAME: {
      return {
        ...state,
        currentDocumentName: action.payload,
      };
    }
    case SIGN_DOCUMENT: {
      return {
        ...state,
        isDocumentSigned: action.payload.status,
      };
    }
    case IS_SEEN: {
      return {
        ...state,
      };
    }
    case GET_CURRENT_DOCUMENT: {
      return {
        ...state,
        currentDocument: action.payload,
      };
    }
    case CHANGE_MODAL_VIEW: {
      return {
        ...state,
        is_modal_open: action.payload,
      };
    }
    case SEND_DOCUMENT: {
      return {
        ...state,

        documentsList: [...state.documentsList, action.payload],
      };
    }
    case RESET_CURRENT_DOCUMENT: {
      return {
        ...state,
        currentDocument: action.payload,
      };
    }
  }
};

export default documentsReducer;
