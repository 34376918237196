import React, { Component } from "react";
import { connect } from "react-redux";
import { users } from "../_store/_actions";
import { auth } from "../_store/_actions";
import { NavLink } from "react-router-dom";
import AppBurgerMenu from "./components/global/AppBurgerMenu";
import AppNavigation from "./components/global/AppNavigation";
import AppLoggedUser from "./components/global/AppLoggedUser";
import { getAuthData } from "../_store/_actions/services/getAuthData";
import { ToastContainer, toast } from "react-toastify";
import {
  UikButton,
  UikFormInputGroup,
  Uikon,
  UikInput,
  UikWidget,
  UikWidgetContent,
  UikSelect,
  UikContainerVertical,
  UikContainerHorizontal,
  UikLayoutMain,
  UikTopBar,
  UikWidgetHeader,
  UikNavLink,
} from "../@uik";
import "../@uik/styles.css";
import "../App/styles/registrationPage.css";

class RegistrationPage extends Component {
  state = {
    phase: "firstPhase",
    username: "",
    password: "temporaryPass123",
    passwordConfirm: "temporaryPass123",
    email: "",
    first_name: "",
    last_name: "",
    emailInputError: "",
    usernameInputError: "",
    passwordInputError: "",
    firstNameInputError: "",
    lastNameInputError: "",
    positionInputError: "",
    roleInputError: "",
    companyInputError: "",
    componentToGenerate: "user-list",
    groupsInputError: "",
    profile: {
      company: "",

      position: "",
    },
    groups: [2],
    currentUserGroups: [],
    stayLogged: false,
    showPassword: false,
    showPasswordConfirm: false,
    currentUserGroupFlag: "",
  };

  GetGroupOptions = (value) => {
    switch (value) {
      case 1: {
        return null;
      }
      case 2: {
        return {
          value: 1,
          label: "Guest",
        };
        //tu dodać usera jesli zmieni się backend
      }
      case 3: {
        return (
          {
            value: 1,
            label: "Guest",
          },
          {
            value: 2,
            label: "User",
          },
          {
            value: 3,
            label: "Admin",
          }
        );
      }
      default:
        break;
    }
  };
  componentDidMount() {
    this.props.getAuthData();
  }

  componentDidUpdate() {
    if (
      this.props.authData.groups[0] === 2 &&
      this.state.currentUserGroupFlag === ""
    ) {
      const employeeArray = [{ value: 1, label: "Guest" }];
      this.setState({
        currentUserGroupFlag: true,
        currentUserGroups: [...this.state.currentUserGroups, ...employeeArray],
      });
    }
    if (
      this.props.authData.groups[0] === 3 &&
      this.state.currentUserGroupFlag === ""
    ) {
      const ownerArray = [
        {
          value: 1,
          label: "Guest",
        },
        {
          value: 2,
          label: "User",
        },
        {
          value: 3,
          label: "Admin",
        },
      ];
      this.setState({
        currentUserGroupFlag: true,
        currentUserGroups: [...this.state.currentUserGroups, ...ownerArray],
      });
    }
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      username: e.target.value,
    });
  };

  handleUsernameChange = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  handleFirstNameChange = (e) => {
    this.setState({
      first_name: e.target.value,
    });
  };

  handleLastNameChange = (e) => {
    this.setState({
      last_name: e.target.value,
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handlePasswordConfirmChange = (e) => {
    this.setState({
      passwordConfirm: e.target.value,
    });
  };

  handlePositionChange = (e) => {
    this.setState({
      profile: {
        ...this.state.profile,
        position: e.target.value,
      },
    });
  };
  handleCompanyChange = (e) => {
    this.setState({
      profile: {
        ...this.state.profile,
        company: e.target.value,
      },
    });
  };
  handleChangeWindow = () => {
    this.props.switchUsersListWindows(this.state.componentToGenerate);
  };
  handleSelectChange = (e) => {
    this.setState({
      groups: [e.value],
    });
  };
  handleStayLogged = (e) => {
    this.setState({
      stayLogged: e.target.checked,
    });
  };

  notify = () =>
    toast("User has been added", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 1,
    });

  checkIfEmpty = () => {
    const requiredField = "Required";

    if (this.state.email === "") {
      this.setState({
        emailInputError: requiredField,
      });
    }
    if (this.state.groups === "" || []) {
      this.setState({
        groupsInputError: requiredField,
      });
    }

    if (this.state.username === "") {
      this.setState({
        usernameInputError: requiredField,
      });
    }
    if (this.state.profile.position === "") {
      this.setState({
        positionInputError: requiredField,
      });
    }
    if (this.state.profile.company === "") {
      this.setState({
        companyInputError: requiredField,
      });
    } else return true;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let formIsReadyToSend = this.checkIfEmpty();

    if (formIsReadyToSend) {
      await this.props.register(
        this.state.username,
        this.state.password,
        this.state.passwordConfirm,
        this.state.email,
        this.state.first_name,
        this.state.last_name,
        this.state.stayLogged,
        this.state.profile,
        this.state.groups
      );
      this.notify();
      setInterval(function () {
        window.location.href = `/users`;
      }, 2000);
    }
  };
  render() {
    const groupOptions = this.state.currentUserGroups;
    return (
      <div className="page-wrap">
        <UikContainerHorizontal>
          {this.props.isMenuOpen ? <AppNavigation /> : null}
          <UikContainerVertical>
            <UikTopBar className="application-top-bar">
              <AppBurgerMenu />
              <AppLoggedUser />
            </UikTopBar>
            <UikLayoutMain>
              <UikWidget className="profile-content">
                <UikNavLink highlighted Component={NavLink} to="./users">
                  <Uikon>arrow_left</Uikon>Return to user list
                </UikNavLink>
                <UikWidgetHeader>Invite user</UikWidgetHeader>
                <UikWidgetContent>
                  <form onSubmit={this.handleSubmit}>
                    <UikFormInputGroup className="registration-form">
                      <UikInput
                        className="registration-form email-input"
                        label="Email"
                        type="email"
                        errorMessage={this.state.emailInputError}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                      />

                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          className="registration-form first-name-input"
                          label="First name"
                          errorMessage={this.state.firstNameInputError}
                          type="text"
                          value={this.state.first_name}
                          onChange={this.handleFirstNameChange}
                        />
                        <UikInput
                          className="registration-form last-name-input"
                          label="Last name"
                          errorMessage={this.state.lastNameInputError}
                          type="text"
                          value={this.state.last_name}
                          onChange={this.handleLastNameChange}
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          label="Organisation"
                          type="text"
                          errorMessage={this.state.companyInputError}
                          value={this.state.company}
                          onChange={this.handleCompanyChange}
                        ></UikInput>
                        <UikInput
                          label="Job title"
                          type="text"
                          errorMessage={this.state.positionInputError}
                          value={this.state.profile.position}
                          onChange={this.handlePositionChange}
                        ></UikInput>
                      </UikFormInputGroup>
                      <UikSelect
                        placeholder={groupOptions[1]?.label}
                        options={groupOptions}
                        onChange={this.handleSelectChange}
                      />
                      {this.state.groupsInputError !== "" ? (
                        <p style={{ color: "#E6492D" }}>Required</p>
                      ) : null}

                      <UikButton primary lg type="submit">
                        Invite
                      </UikButton>
                      <p className="registration-error-message">
                        {this.props.errorMessage}
                      </p>
                    </UikFormInputGroup>
                  </form>
                </UikWidgetContent>
              </UikWidget>
            </UikLayoutMain>
            <ToastContainer />
          </UikContainerVertical>
        </UikContainerHorizontal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    registrationMessage: state.auth.registrationMessage,
    errorMessage: state.auth.errorMessage,
    autoLogin: state.auth.autoLogin,
    isMenuOpen: state.menu.isMenuOpen,
    authData: state.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchUsersListWindows: (componentToGenerate) => {
      return dispatch(users.switchUsersListWindows(componentToGenerate));
    },
    getAuthData: () => dispatch(getAuthData()),
    register: (
      username,
      password,
      passwordConfirm,
      email,
      first_name,
      last_name,
      stayLogged,
      profile,
      groups
    ) => {
      return dispatch(
        auth.register(
          username,
          password,
          passwordConfirm,
          email,
          first_name,
          last_name,
          stayLogged,
          profile,
          groups
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
