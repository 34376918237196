import eventStatuses from "../../../_config/eventStatuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";

const {
  ADD_ORGANISATION,
  GET_ORGANISATION,
  SET_LOGO,
  GET_LOGO,
} = eventStatuses.organisations;
const { API_URL, ORGANIZATION, LOGOS } = address;

export const getOrganisation = () => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${ORGANIZATION}`, {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    const organisation = await response.json();
    dispatch({ type: GET_ORGANISATION, payload: organisation });
  } catch (error) {
    console.log(error);
  }
};

export const addOrganisation = (organisation, logoId) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${ORGANIZATION}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({ ...organisation, logo: logoId }),
    });
    const dispatchOrganisation = await response.json();
    dispatch({ type: ADD_ORGANISATION, payload: dispatchOrganisation });
  } catch (error) {
    console.log(error);
  }
};

export const uploadLogo = (logo) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${LOGOS}`, {
        method: "POST",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: logo,
      });
      const uploadedLogo = await response.json();
      dispatch({
        type: SET_LOGO,
        payload: uploadedLogo,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getLogo = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${LOGOS}/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      const attachmentUrl = await response.json();
      dispatch({ type: GET_LOGO, payload: attachmentUrl });
    } catch (e) {
      console.log(e);
    }
  };
};
