import statuses from "../../../_config/statuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";

const {
  STATUS_OK,
  STATUS_CREATED,
  STATUS_UNAUTHORIZED,
  STATUS_FORBIDDEN,
  INTERNAL_ERROR,
} = statuses;

const { API_URL, REGISTER } = address;

const fetchRegister = (
  username,
  password,
  email,
  first_name,
  last_name,
  profile,
  groups,
  dispatchRegistrationSuccessful,
  dispatchRegistrationError,
  dispatchRegistrationFailed
) => {
  fetch(`${API_URL}${REGISTER}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Token " + localStorage.getItem("token"),
    },
    body: JSON.stringify({
      username,
      password,
      email,
      first_name,
      last_name,
      profile,
      groups,
    }),
  })
    .then((response) => {
      if (response.status < INTERNAL_ERROR) {
        return response.json().then((data) => {
          return { status: response.status, data };
        });
      } else {
        throw response;
      }
    })
    .then((response) => {
      if (response.status === STATUS_OK || STATUS_CREATED) {
        dispatchRegistrationSuccessful(response);
      } else if (
        response.status === STATUS_FORBIDDEN ||
        response.status === STATUS_UNAUTHORIZED
      ) {
        return dispatchRegistrationError(response);
      } else {
        return dispatchRegistrationFailed(response);
      }
    });
};

export default fetchRegister;
