const address = {
  // TODO: przenieść do .env lub stworzyć config prod/staging dla adresu url
  API_URL: "https://esignatures-api.matters24.com/api/v1/",
  USERS: "users",
  LOGIN: "login",
  RESET: "password_reset",
  REGISTER: "register",
  CONFIRM: "confirm",
  ORGANIZATION: "organization",
  DOCUMENTS: "documents",
  ATTACHMENTS: "attachments",
  VALIDATE: "validate/",
  REMIND_PASSWORD: "remind_password",
  CHANGE_PASSWORD: "change_password",
  AVATARS: "avatars",
  LOGOS: "logos",
};

export default address;
