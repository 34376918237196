import eventStatuses from "../../_config/eventStatuses";

const { GET_AUTHDATA, SET_AVATAR, GET_AVATAR } = eventStatuses.users;

const initState = {
  first_name: "",
  last_name: "",
  avatarId: "",
  avatarUrl: "",
  profile: {
    position: "",
  },
  email: "",
  groups: [],
};

const authData = (state = initState, action) => {
  switch (action.type) {
    case GET_AUTHDATA:
      return { ...action.payload };
    case SET_AVATAR:
      return {
        ...state,
        avatarId: action.payload,
      };
    case GET_AVATAR:
      return {
        ...state,
        avatarUrl: action.payload.file,
      };

    default:
      return state;
  }
};

export default authData;
