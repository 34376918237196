import statuses from "../../../_config/statuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";

const {
  STATUS_OK,
  STATUS_UNAUTHORIZED,
  STATUS_FORBIDDEN,
  INTERNAL_ERROR,
} = statuses;

const { API_URL, RESET, CONFIRM } = address;

const changePassword = (
  password,
  token,
  dispatchPasswordChanged,
  dispatchPasswordChangeFail,
  dispatchPasswordChangeIncorrect
) => {
  const options = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ password, token }),
  };

  fetch(`${API_URL}${RESET}/${CONFIRM}/`, options)
    .then((response) => {
      if (response.status < INTERNAL_ERROR) {
        return response.json().then((data) => {
          return { status: response.status, data };
        });
      } else {
        console.log("Server Error!");
        throw response;
      }
    })
    .then((response) => {
      console.log(response);
      if (response.status === STATUS_OK) {
        dispatchPasswordChanged(response);
      } else if (
        response.status === STATUS_FORBIDDEN ||
        response.status === STATUS_UNAUTHORIZED
      ) {
        dispatchPasswordChangeFail(response);
      } else {
        dispatchPasswordChangeIncorrect(response);
      }
    });
};

export default changePassword;
