const recievedDocuments = (documents) =>
  documents.filter((document) => document.status === "Odebrane");

const SendedDocuments = (documents) =>
  documents.filter((document) => document.status === "SENT");

const storedDocuments = (documents) =>
  documents.filter((document) => document.status === "DRAFT");
const withdrawedDocuments = (documents) =>
  documents.filter((document) => document.status === "WITHDRAWED");
const signedDocuments = (documents) =>
  documents.filter((document) => document.status === "SIGNED");
const rejectedDocuments = (documents) =>
  documents.filter((document) => document.status === "REJECTED");
const docsFilterService = (documents, value) => {
  switch (value) {
    case "RECIEVED_DOCUMENTS":
      return recievedDocuments(documents);
    case "DRAFT_DOCUMENTS":
      return storedDocuments(documents);
    case "SENDED_DOCUMENTS":
      return SendedDocuments(documents);
    case "ALL_DOCUMENTS":
      return documents;
    case "WITHDRAWED_DOCUMENTS":
      return withdrawedDocuments(documents);
    case "SIGNED_DOCUMENTS":
      return signedDocuments(documents);
    case "REJECTED_DOCUMENTS":
      return rejectedDocuments(documents);
    default:
      return documents;
  }
};

export default docsFilterService;
