import React, { Component } from "react";
import { connect } from "react-redux";
import { users } from "../../../_store/_actions";
import { ToastContainer, toast } from "react-toastify";
import avatar_default from "../../img/avatar_default.jpg";
import {
  UikButton,
  Uikon,
  UikFormInputGroup,
  UikNavLink,
  UikInput,
  UikWidgetContent,
  UikWidgetHeader,
  UikSelect,
} from "../../../@uik";
import { getUserList, editUser } from "../../../_store/_actions/users";
import "../../../App/styles/usersEdit.css";
class UsersEdit extends Component {
  state = {
    avatar: avatar_default,
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    profile: {
      position: "",
      role: "",
      company: "",
    },
    groups: "",
    componentToGenerate: "user-list",
    currentUserGroups: [
      {
        value: 1,
        label: "Guest",
      },
      {
        value: 2,
        label: "User",
      },
      {
        value: 3,
        label: "Admin",
      },
    ],
    currentUserGroupFlag: false,
  };

  handleChangeWindow = (e) => {
    this.props.switchUsersListWindows(this.state.componentToGenerate);
  };

  handleAvatarChange = (e) => {
    this.setState({
      avatar: URL.createObjectURL(e.target.files[0]),
    });
  };

  userDataInitialization = (editUserID) => {
    const users = this.props.userList;

    users.filter((user) => {
      const { id, email, first_name, last_name } = user;

      if (editUserID === id) {
        this.setState({
          groups: user.groups,
          email,
          first_name,
          last_name,
          profile: {
            position: user.profile.position,
            role: user.profile.role,
            company: user.profile.company,
          },
        });
      }
      return null;
    });
  };

  componentDidMount() {
    this.props.getUserList();
    this.userDataInitialization(this.props.editUserID);
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleFirstNameChange = (e) => {
    this.setState({
      first_name: e.target.value,
    });
  };

  handleLastNameChange = (e) => {
    this.setState({
      last_name: e.target.value,
    });
  };

  handlePositionChange = (e) => {
    this.setState({
      ...this.state,
      profile: {
        ...this.state.profile,
        position: e.target.value,
      },
    });
  };

  handleAccountRoleChange = (e) => {
    this.setState({
      groups: [e.value],
    });
  };

  notify = () =>
    toast("user has been edited", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 1,
    });
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.editUser(this.state, this.props.editUserID);
    this.notify();
  };
  getUserGroup = (group) => {
    if (group === 3) {
      return "Admin";
    }
    if (group === 2) {
      return "User";
    }
    if (group === 1) {
      return "Guest";
    }
    return "";
  };
  render() {
    const groupOptions = this.state.currentUserGroups;

    return (
      <>
        <UikNavLink
          onClick={this.handleChangeWindow}
          className="active backlink"
        >
          <Uikon>arrow_left</Uikon>Back to user list
        </UikNavLink>
        <UikWidgetHeader
          rightEl={
            <UikButton primary type="submit" form="edit-user-form">
              Save
            </UikButton>
          }
        >
          Edit user
        </UikWidgetHeader>
        <UikWidgetContent>
          <form id="edit-user-form" onSubmit={this.handleSubmit}>
            <UikFormInputGroup direction="horizontal">
              <UikFormInputGroup className="add-image-input-wrap">
                <h3>User's image</h3>
                <label htmlFor="add-image-input">
                  <img src={this.state.avatar} alt="add user's avatar" />
                </label>
                <UikInput
                  className="add-image-input"
                  type="file"
                  onChange={this.handleAvatarChange}
                />
              </UikFormInputGroup>

              <UikFormInputGroup className="user__edit__form">
                <h3>User's personal data</h3>

                <UikInput
                  className="user-form email-input"
                  label="Email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
                <UikInput
                  className="user-form first-name-input"
                  label="First name"
                  type="text"
                  value={this.state.first_name}
                  onChange={this.handleFirstNameChange}
                />
                <UikInput
                  className="user-form last-name-input"
                  label="Last name"
                  type="text"
                  value={this.state.last_name}
                  onChange={this.handleLastNameChange}
                />
                <UikInput
                  className="user-form position-input"
                  label="Job title"
                  type="text"
                  value={this.state.profile.position}
                  onChange={this.handlePositionChange}
                />

                <h3>Account's options</h3>
                <UikSelect
                  className="user-form account-role"
                  placeholder={groupOptions[1]?.label}
                  options={groupOptions}
                  onChange={this.handleAccountRoleChange}
                />
              </UikFormInputGroup>
            </UikFormInputGroup>
          </form>
          <ToastContainer />
        </UikWidgetContent>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentToGenerate: state.users.componentToGenerate,
    userList: state.users.userList,
    editUserID: state.users.editUserID,
    authData: state.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (userData, id) => dispatch(editUser(userData, id)),
    getUserList: () => dispatch(getUserList()),
    switchUsersListWindows: (componentToGenerate) => {
      return dispatch(users.switchUsersListWindows(componentToGenerate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersEdit);
