import eventStatuses from "../../_config/eventStatuses";

const { OPEN_MENU } = eventStatuses.menu;

const initialState = {
  isMenuOpen: false
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        ...action.data,
        isMenuOpen: !action.isMenuOpen
      };

    default:
      return state;
  }
}
