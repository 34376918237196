import eventStatuses from "../../../_config/eventStatuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";

const { API_URL, USERS } = address;
const { GET_ME, GET_EMAIL } = eventStatuses.users;
const options = {
  headers: {
    "content-type": "application/json",
    Authorization: "Token " + localStorage.getItem("token"),
  },
};

export const getMe = (id) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${USERS}/me/`, options);
    const myData = await response.json();

    dispatch({
      type: GET_ME,
      payload: myData,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getEmail = (token) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${USERS}/me/`, {
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + token,
      },
    });
    const email = await response.json();

    dispatch({ type: GET_EMAIL, payload: email.email });
  } catch (e) {
    console.log(e);
  }
};
