import eventStatuses from "../../../_config/eventStatuses";
import address from "../../../_config/address";
import fetch from "../../../fetch";

const { API_URL, USERS } = address;
const { GET_AUTHDATA, EDIT_USER } = eventStatuses.users;
const getOptions = () => ({
  headers: {
    "content-type": "application/json",
    Authorization: "Token " + localStorage.getItem("token"),
  },
});
export const getAuthData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${USERS}/me/`, getOptions());
      const myself = await response.json();

      dispatch({
        type: GET_AUTHDATA,
        payload: myself,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeAuthData = (data, avatar) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${USERS}/me/`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        ...data,
        ...data.profile.avatar,
      }),
    });

    const editedUserData = await response.json();

    dispatch({
      type: EDIT_USER,
      payload: editedUserData,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAuthDataWithToken = (token) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}${USERS}/me/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Token " + token,
      },
    });
    const myData = await response.json();
    dispatch({ type: GET_AUTHDATA, payload: myData });
  } catch (error) {
    console.log(error);
  }
};
