import store from "./_store/store";
import eventStatuses from "../src/_config/eventStatuses";
const { LOGOUT_SUCCESSFUL } = eventStatuses.auth;

export default async function interceptedFetch(...args) {
  try {
    const response = await fetch(...args);

    if (response.status === 401 || response.status === 403) {
      store.dispatch({ type: LOGOUT_SUCCESSFUL });
    }

    return response;
  } catch (error) {
    console.log({ error });
  }
}
