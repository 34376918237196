import React, { Component } from "react";
import { connect } from "react-redux";
import { docs } from "../../../_store/_actions";
import { getUserList } from "../../../_store/_actions/users";
import "../../../App/styles/DocsAdd.css";
import ReciepentModal from "../../components/InviteReciepent";
import { sendDocument } from "../../../_store/_actions/sendDocumentService";
import {
  UikSelect,
  UikButton,
  UikButtonGroup,
  UikDivider,
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikNavLink,
  UikWidgetContent,
  UikWidgetHeader,
} from "../../../@uik";
import {
  getDocuments,
  addDraft,
  uploadAttachment,
  documentNewReciepentWindowChange,
} from "../../../_store/_actions/services/documentServices";
import { getAuthData } from "../../../_store/_actions/services/getAuthData";
class DocsAdd extends Component {
  state = {
    id: "",
    creator: "",
    name: "",
    assignations: [],
    componentToGenerate: "docs-list",
    refreshRequired: false,
    created_at: new Date(),
    status: "",
    currentReciepent: [],
    modal_open: false,
  };

  componentDidMount() {
    this.props.getUserList();
    this.props.getAuthData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.newReciepent !== this.props.newReciepent) {
      const reciepent = {
        profile: this.props.newReciepentId,
        permission: "S",
        is_seen: false,
        is_signed: false,
        email: this.props.newReciepent,
      };

      this.setState({
        modal_open: false,
        assignations: [...this.state.assignations, reciepent],
        currentReciepent: [
          ...this.state.currentReciepent,
          this.props.newReciepent + " ",
        ],
      });
    }

    if (this.state.creator === "") {
      this.setState({
        creator: this.props.docsender,
      });
    }

    if (prevProps.is_modal_open !== this.props.is_modal_open) {
      this.setState({
        modal_open: this.props.is_modal_open,
      });
    }
  }

  handleChangeWindow = () => {
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };

  handleNewReceipentOpen = () => {
    this.props.switchDocsListWindows("docs-new-rec");
  };

  handleFileLoad = (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    this.props.uploadAttachment(formData);
  };

  handleSenderChange = (e) => {
    this.setState({
      creator: e.target.value,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleRecipientsChange = (e) => {
    const reciepent = {
      profile: e.value,
      permission: "S",
      is_seen: false,
      is_signed: false,
      email: e.label,
    };

    this.setState({
      assignations: [...this.state.assignations, reciepent],
      currentReciepent: [...this.state.currentReciepent, reciepent.email + " "],
    });
  };

  handleReciepentDelete = (id) => {
    const reciepentToDelete = this.state.assignations.filter((reciepent) => {
      return reciepent.id !== id;
    });
    this.setState({
      assignations: [reciepentToDelete],
      currentReciepent: [reciepentToDelete],
    });
  };

  handleReciepentClear = () => {
    this.setState({
      assignations: [],
      currentReciepent: [],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.props.sendDocument(this.state);
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };

  handleSaveDraft = (e) => {
    e.preventDefault();
    this.setState({
      status: "DRAFT",
    });
    this.props.addDraft(this.state);
    this.props.switchDocsListWindows(this.state.componentToGenerate);
  };
  handleNewRecipent = () => {
    this.setState({
      modal_open: true,
    });
    this.props.documentNewReciepentWindowChange(true);
  };
  render() {
    const userOptions = this.props.userList.map((user) => {
      if (user.profile === null) {
        return {
          value: user.auth_token,
          label: user.email,
        };
      } else {
        return {
          value: user.profile.id,
          label: user.email,
        };
      }
    });
    return (
      <>
        <div className="modal__container">
          {this.state.modal_open && <ReciepentModal />}
        </div>
        <div
          className={
            this.state.modal_open ? "document__add__form__container" : ""
          }
        >
          <UikNavLink
            onClick={this.handleChangeWindow}
            className="active backlink"
          >
            <Uikon>arrow_left</Uikon>Return to document list
          </UikNavLink>
          <UikWidgetHeader>
            <div>
              New document {this.props.errorMessage ? <UikDivider /> : null}
              <span> {this.props.errorMessage} </span>
            </div>
          </UikWidgetHeader>
          <UikWidgetContent>
            <form id="add-doc-form" onSubmit={this.handleSubmit}>
              <UikFormInputGroup>
                <h3> Basic informations </h3>

                <UikInput
                  className="add-doc-form name-input"
                  label="Document name"
                  type="text"
                  value={this.state.docname}
                  onChange={this.handleNameChange}
                />
                <h3> Attached files </h3>
                <UikInput
                  className="add-doc-form file-input"
                  label="Document content"
                  type="file"
                  value={this.state.dockfile}
                  onChange={this.handleFileLoad}
                  accept="application/PDF"
                />
                <h3> Recipients </h3>
                <UikSelect
                  className="user-form recipient-input"
                  label="Select reciepents"
                  type="text"
                  options={userOptions}
                  onChange={this.handleRecipientsChange}
                />
                <UikInput
                  readOnly
                  label="reciepents"
                  value={this.state.currentReciepent}
                />

                <UikButtonGroup>
                  <UikButton primary onClick={this.handleNewRecipent}>
                    New recipient
                  </UikButton>
                  <UikButton
                    onClick={this.handleReciepentClear}
                    className="document__add__button__clear__reciepents"
                  >
                    Clear reciepents
                  </UikButton>
                </UikButtonGroup>
                <UikButtonGroup>
                  <UikButton primary form="add-doc-form" type="submit">
                    Send document
                  </UikButton>
                  <UikButton onClick={this.handleSaveDraft}>
                    Save as draft
                  </UikButton>
                </UikButtonGroup>
              </UikFormInputGroup>
            </form>
          </UikWidgetContent>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docsender: state.authData.username,
    componentToGenerate: state.docs.componentToGenerate,
    errorMessage: state.users.errorMessage,
    userList: state.users.userList,
    docfileId: state.documents.docfileId,
    currentDocumentDraftId: state.documents.currentDocumentDraftId,
    newReciepent: state.auth.newReciepent,
    isModalOpen: state.auth.isModalOpen,
    newReciepentGroup: state.auth.newReciepentGroup,
    is_modal_open: state.documents.is_modal_open,
    newReciepentId: state.auth.newReciepentId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendDocument: (document) => dispatch(sendDocument(document)),
    documentNewReciepentWindowChange: (value) =>
      dispatch(documentNewReciepentWindowChange(value)),

    getAuthData: (id) => dispatch(getAuthData(id)),
    uploadAttachment: (attachment) => dispatch(uploadAttachment(attachment)),
    getUserList: () => dispatch(getUserList()),
    addDraft: (document) => dispatch(addDraft(document)),
    getDocuments: () => dispatch(getDocuments()),
    switchDocsListWindows: (componentToGenerate) => {
      return dispatch(docs.switchDocsListWindows(componentToGenerate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsAdd);
