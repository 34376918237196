import fetchUserList from "./services/ShowUsersService";
import AddNewUser from "./services/AddNewUserService";
import eventStatuses from "../../_config/eventStatuses";
import address from "../../_config/address";
import fetch from "../../fetch";
const {
  GO_TO_USERSLIST,
  GO_TO_USER_ADD,
  GO_TO_USER_EDIT,
  USERLIST_LOADED,
  USER_ADDED,
  AUTHENTICATION_ERROR,
  USER_ADDING_FAILED,
  GET_USERS,
  EDIT_USER,
  DELETE_USER,
  DEACTIVATE_USER,
  SET_AVATAR,
  ACTIVATE_USER,
  GET_AVATAR,
} = eventStatuses.users;
const { API_URL, USERS, AVATARS } = address;
const getOptions = () => ({
  headers: {
    "content-type": "application/json",
    Authorization: "Token " + localStorage.getItem("token"),
  },
});
export const switchUsersListWindows = (componentToGenerate, id) => {
  return (dispatch) => {
    if (componentToGenerate === "user-list") {
      dispatch({ type: GO_TO_USERSLIST, componentToGenerate });
    } else if (componentToGenerate === "user-add") {
      dispatch({ type: GO_TO_USER_ADD, componentToGenerate });
    } else if (componentToGenerate === "user-edit") {
      dispatch({ type: GO_TO_USER_EDIT, componentToGenerate, id });
    }
  };
};

export const showUserList = () => {
  return (dispatch, getState) => {
    const dispatchUserlistLoaded = function (users) {
      dispatch({
        type: USERLIST_LOADED,
        data: users,
      });
    };

    return fetchUserList(dispatchUserlistLoaded);
  };
};

export const addNewUser = (
  username,
  password,
  email,
  first_name,
  last_name,
  avatar
) => {
  return (dispatch, getState) => {
    const dispatchUserAdded = function (response) {
      dispatch({
        type: USER_ADDED,
        data: response.data,
      });
      return response.data;
    };

    const dispatchUserAuthError = function (response) {
      dispatch({ type: AUTHENTICATION_ERROR, data: response.data });
      throw response.data;
    };

    const dispatchUserAddingFailed = function (response) {
      dispatch({ type: USER_ADDING_FAILED, data: response.data });
      throw response.data;
    };

    return AddNewUser(
      username,
      password,
      email,
      first_name,
      last_name,
      dispatchUserAdded,
      dispatchUserAuthError,
      dispatchUserAddingFailed
    );
  };
};

export const deleteExistingUser = (id) => {
  return async (dispatch) => {
    try {
      await fetch(`${API_URL}${USERS}/${id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_USER, payload: { id } });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getUserList = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${USERS}`, getOptions());
      const users = await response.json();
      let usersList = users.results;

      dispatch({ type: GET_USERS, payload: usersList });
    } catch (error) {
      console.log(error);
    }
  };
};

export const editUser = (userData, id) => {
  return async (dispatch) => {
    try {
      await fetch(`${API_URL}${USERS}/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(userData),
      });

      dispatch({ type: EDIT_USER, payload: { ...userData } });
    } catch (error) {
      console.log(error);
    }
  };
};

export const deactivateUser = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${USERS}/${id}/deactivate/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(),
      });
      const deactivatedUser = await response.json();
      dispatch({ type: DEACTIVATE_USER, payload: deactivatedUser });
    } catch (error) {
      console.log(error);
    }
  };
};

export const activateUser = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${USERS}/${id}/activate/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(),
      });
      const activatedUser = await response.json();
      dispatch({ type: ACTIVATE_USER, payload: activatedUser });
    } catch (error) {
      console.log(error);
    }
  };
};

export const uploadAvatar = (attachment) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${AVATARS}`, {
        method: "POST",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: attachment,
      });
      const uploadedFile = await response.json();
      dispatch({
        type: SET_AVATAR,
        payload: uploadedFile.id,
      });
      return uploadedFile;
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAvatar = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_URL}${AVATARS}/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      const attachmentUrl = await response.json();
      dispatch({ type: GET_AVATAR, payload: attachmentUrl });
    } catch (e) {
      console.log(e);
    }
  };
};
