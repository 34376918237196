import eventStatuses from "../../_config/eventStatuses";

const {
  USER_LOADED,
  REGISTRATION_SUCCESFULL,
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL,
} = eventStatuses.auth;

const { EMAIL_CORRECT } = eventStatuses.remind;

const persistToken = (store) => (next) => (action) => {
  switch (action.type) {
    case USER_LOADED:
      console.log(store.getState().auth);
      break;

    case EMAIL_CORRECT:
      localStorage.setItem("email", action.email);
      break;

    case REGISTRATION_SUCCESFULL:
      break;

    case LOGIN_SUCCESSFUL:
      localStorage.setItem("token", action.data.token);
      localStorage.setItem("autologin", action.stayLogged);
      localStorage.setItem("username", action.user);
      break;

    case LOGOUT_SUCCESSFUL:
      localStorage.removeItem("token");
      localStorage.removeItem("autologin");
      localStorage.removeItem("username");
      break;

    default:
      break;
  }

  return next(action);
};

export default persistToken;
