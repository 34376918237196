import React, { Component } from "react";

import { connect } from "react-redux";

import { users } from "../../../_store/_actions";

import { UikDropdownItem } from "../../../@uik";
import {
  getUserList,
  deactivateUser,
  activateUser,
} from "../../../_store/_actions/users";
class UsersDropdownMenu extends Component {
  state = {
    componentToGenerate: "user-edit",
    id: this.props.userid,
    username: this.props.username,
    actualUser: this.props.actualUser,
    active: this.props.active,
  };

  handleChangeWindow = () => {
    this.props.switchUsersListWindows(
      this.state.componentToGenerate,
      this.state.id
    );
  };

  handleUserDeactivate = (id) => {
    if (this.props.username === this.props.actualUser) {
      alert("You cant deactivate yourself!");
    } else {
      this.props.deactivateUser(id);
    }
  };

  handleUserDelete = () => {
    if (this.props.username === this.props.actualUser) {
      alert("You cant delete yourself!");
    } else {
      this.props.deleteExistingUser(this.state.id);
    }
  };

  handleUserActivate = (id) => {
    this.props.activateUser(id);
  };

  render() {
    return (
      <>
        <UikDropdownItem onClick={this.handleChangeWindow}>
          Edit user
        </UikDropdownItem>

        <UikDropdownItem onClick={this.handleUserDelete}>
          Delete user
        </UikDropdownItem>
        {this.state.active === false && (
          <UikDropdownItem
            onClick={() => this.handleUserActivate(this.state.id)}
          >
            Activate user
          </UikDropdownItem>
        )}

        {this.state.active && (
          <UikDropdownItem
            onClick={() => this.handleUserDeactivate(this.state.id)}
          >
            Deactivate user
          </UikDropdownItem>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentToGenerate: state.users.componentToGenerate,
    actualUser: state.auth.user,
    userList: state.users.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activateUser: (id) => dispatch(activateUser(id)),
    deactivateUser: (id) => dispatch(deactivateUser(id)),
    getUserList: () => dispatch(getUserList()),
    switchUsersListWindows: (componentToGenerate, id) => {
      return dispatch(users.switchUsersListWindows(componentToGenerate, id));
    },
    deleteExistingUser: (id) => {
      return dispatch(users.deleteExistingUser(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersDropdownMenu);
