import { combineReducers } from "redux";

import auth from "./auth";
import docs from "./docs";
import menu from "./menu";
import remind from "./remind";
import users from "./users";
import organisations from "./Organisations";
import documents from "./documents";
import authData from "./authData";
import verificate from "./verificate";

const uniApp = combineReducers({
  auth,
  docs,
  menu,
  remind,
  users,
  organisations,
  documents,
  authData,
  verificate,
});

export default uniApp;
