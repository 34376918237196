import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { remind } from "../../_store/_actions";
import { changePasswordNew } from "../../_store/_actions/remind";
import {
  UikButton,
  UikFormInputGroup,
  UikHeadline,
  UikInput,
  Uikon,
  UikWidget,
  UikWidgetBottomCta,
  UikWidgetContent,
} from "../../@uik";

class RemindPassReset extends Component {
  state = {
    password: "",
    token: "",
    passwordInputError: "",
    showPassword: false,
    hasClicked: false,
    repeatPassword: "",
    repeatPasswordError: "",
  };

  getQueryVariable(tokenToReset) {
    let changePassQuery = window.location.search.substring(1);
    let changePassQueryVariables = changePassQuery.split("&");
    for (let i = 0; i < changePassQueryVariables.length; i++) {
      let pair = changePassQueryVariables[i].split("=");
      if (pair[0] === tokenToReset) {
        return pair[1];
      }
      return false;
    }
  }

  componentDidMount() {
    const token = this.getQueryVariable("token");
    this.setState({
      token,
    });
  }

  handlePassChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  handleRepeatPassChange = (e) => {
    this.setState({
      repeatPassword: e.target.value,
    });
  };

  handlePassVisibility = (e) => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handlePassSubmit = async (e) => {
    e.preventDefault();
    if (this.state.password === "" && this.state.repeatPassword === "") {
      this.setState({
        passwordInputError: "Required",
        repeatPasswordError: "Required",
      });
    }
    if (this.state.password === "") {
      this.setState({
        passwordInputError: "Required",
      });
    } else if (this.state.repeatPassword === "") {
      this.setState({
        repeatPasswordError: "Required",
      });
    } else if (this.state.password !== this.state.repeatPassword) {
      this.setState({
        passwordInputError: "Password doesn't match",
        repeatPasswordError: "Password doesn't match",
      });
    } else {
      await this.props.changePasswordNew(
        this.state.password,
        localStorage.getItem("token")
      );
      document.location = "/profile";
    }
  };

  render() {
    if (this.props.passChanged) {
      return (
        <div className="login-wrap">
          <UikWidget padding>
            <UikHeadline className="form-headline">
              Remind your password (4/4)
            </UikHeadline>
            <UikWidgetContent>
              <UikFormInputGroup>
                <h2>{this.props.errorMessage}</h2>
                <UikButton primary lg Component={Link} to="/">
                  Login
                </UikButton>
              </UikFormInputGroup>
            </UikWidgetContent>
          </UikWidget>
        </div>
      );
    } else
      return (
        <div className="login-wrap">
          <UikWidget padding>
            <UikHeadline className="form-headline">
              Change your password
            </UikHeadline>
            <UikWidgetContent>
              <form onSubmit={this.handlePassSubmit}>
                <UikFormInputGroup>
                  <UikInput
                    className="user-form email-input"
                    label="Email"
                    type="email"
                    value={this.props.emailToReset}
                    readOnly
                  />
                  <UikInput
                    className="password-input"
                    label="Your new password"
                    type={this.state.showPassword ? "text" : "password"}
                    icon={
                      <Uikon
                        className="visibility-icon"
                        onClick={this.handlePassVisibility}
                      >
                        view_simple
                      </Uikon>
                    }
                    iconPosition="right"
                    errorMessage={this.state.passwordInputError}
                    value={this.state.password}
                    onChange={this.handlePassChange}
                  />
                  <UikInput
                    className="Password-input"
                    label="Repeat your password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.repeatPassword}
                    onChange={this.handleRepeatPassChange}
                    icon={
                      <Uikon
                        className="visibility-icon"
                        onClick={this.handlePassVisibility}
                      >
                        view_simple
                      </Uikon>
                    }
                    iconPosition="right"
                    errorMessage={this.state.repeatPasswordError}
                  />
                  <UikButton primary lg type="submit">
                    Send
                  </UikButton>
                </UikFormInputGroup>
              </form>
            </UikWidgetContent>
            <UikWidgetBottomCta Component={Link} to="/">
              <p>
                back to <span style={{ color: "#1665D8" }}>MATTERS eSignatures</span>
              </p>
            </UikWidgetBottomCta>
            <UikWidgetBottomCta>
              <p>{this.props.errorMessage}</p>
            </UikWidgetBottomCta>
          </UikWidget>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.remind.errorMessage,
    passChanged: state.remind.passChanged,
    emailToReset: state.remind.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePasswordNew: (password, token) =>
      dispatch(changePasswordNew(password, token)),
    remindChangePassword: (password, token) => {
      return dispatch(remind.remindChangePassword(password, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindPassReset);
