import eventStatuses from "../../_config/eventStatuses";

const {
  GO_TO_DOCSLIST,
  GO_TO_DOCS_ADD,
  GO_TO_DOCS_VIEW,
  GO_TO_DOCS_NEW_RECEIPENT,
} = eventStatuses.docs;

export const switchDocsListWindows = (componentToGenerate, id) => {
  return (dispatch, getState) => {
    if (componentToGenerate === "docs-list") {
      dispatch({ type: GO_TO_DOCSLIST, componentToGenerate });
    } else if (componentToGenerate === "docs-add") {
      dispatch({ type: GO_TO_DOCS_ADD, componentToGenerate });
    } else if (componentToGenerate === "docs-view") {
      dispatch({ type: GO_TO_DOCS_VIEW, componentToGenerate, id });
    } else if (componentToGenerate === "docs-new-rec") {
      dispatch({ type: GO_TO_DOCS_NEW_RECEIPENT, componentToGenerate, id });
    }
  };
};
