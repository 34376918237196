import eventStatuses from "../../_config/eventStatuses";

const {
  GO_TO_DOCSLIST,
  GO_TO_DOCS_ADD,
  GO_TO_DOCS_VIEW,
  GO_TO_DOCS_NEW_RECEIPENT
} = eventStatuses.docs;

const initialState = {
  componentToGenerate: ""
};

export default function docs(state = initialState, action) {
  switch (action.type) {
    case GO_TO_DOCSLIST:
      return {
        ...state,
        ...action.data,
        componentToGenerate: "docs-list"
      };

    case GO_TO_DOCS_ADD:
      return {
        ...state,
        ...action.data,
        componentToGenerate: "docs-add"
      };

    case GO_TO_DOCS_VIEW:
      return {
        ...state,
        ...action.data,
        componentToGenerate: "docs-view"
      };

    case GO_TO_DOCS_NEW_RECEIPENT:
      return {
        ...state,
        ...action.data,
        componentToGenerate: "docs-new-rec"
      };

    default:
      return state;
  }
}
