import eventStatuses from "../../_config/eventStatuses";

const {
  ADD_ORGANISATION,
  GET_ORGANISATION,
  GET_LOGO,
  SET_LOGO,
} = eventStatuses.organisations;

const initState = {
  street: "",
  city: "",
  orgLogo: "",
  name: "",
  post_code: "",
  logoId: "",
  logoUrl: "",
};

const organisations = (state = initState, action) => {
  switch (action.type) {
    case GET_ORGANISATION:
      return action.payload;
    case SET_LOGO:
      return {
        ...state,
        logoId: action.payload.id,
      };
    case GET_LOGO:
      return {
        ...state,
        logoUrl: action.payload.file,
      };
    case ADD_ORGANISATION:
      return {
        state: action.payload,
      };
    default:
      return state;
  }
};

export default organisations;
