const eventStatuses = {
  auth: {
    REGISTRATION_SUCCESFULL: "REGISTRATION_SUCCESFULL",
    REGISTRATION_ERROR: "REGISTRATION_ERROR",
    REGISTRATION_FAILED: "REGISTRATION_FAILED",
    PASSWORD_TOO_SHORT: "PASSWORD_TOO_SHORT",
    PASSWORD_INCORRECT: "PASSWORD_INCORRECT",
    PASSWORDS_NOT_MATCH: "PASSWORDS_NOT_MATCH",
    USER_LOADED: "USER_LOADED",
    LOGIN_SUCCESSFUL: "LOGIN_SUCCESSFUL",
    AUTHENTICATION_ERROR: "AUTHENTICATION_ERROR",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT_SUCCESSFUL: "LOGOUT_SUCCESSFUL",
    REGISTRATION_SUCCESFULL_NEW_RECIEPENT:
      "REGISTRATION_SUCCESFULL_NEW_RECIEPENT",
  },
  docs: {
    GO_TO_DOCSLIST: "GO_TO_DOCSLIST",
    GO_TO_DOCS_ADD: "GO_TO_DOCS_ADD",
    GO_TO_DOCS_VIEW: "GO_TO_DOCS_VIEW",
    GO_TO_DOCS_NEW_RECEIPENT: "GO_TO_DOCS_NEW_RECEIPENT",
  },
  menu: {
    OPEN_MENU: "OPEN_MENU",
  },
  remind: {
    EMAIL_CORRECT: "EMAIL_CORRECT",
    EMAIL_ERROR: "EMAIL_ERROR",
    EMAIL_INCORRECT: "EMAIL_INCORRECT",
    PASSWORD_SUCCESSFULLY_CHANGED: "PASSWORD_SUCCESSFULLY_CHANGED",
    PASSWORD_CHANGE_FAIL: "PASSWORD_CHANGE_FAIL",
    PASSWORD_CHANGE_INCORRECT: "PASSWORD_CHANGE_INCORRECT",
    PASSWORD_TOO_SHORT: "PASSWORD_TOO_SHORT",
    PASSWORD_INCORRECT: "PASSWORD_INCORRECT",
    EMAIL_SEND: "EMAIL_SEND",
    CONFIRM_REGISTRATION: "confirm_registration/",
  },
  users: {
    GO_TO_USERSLIST: "GO_TO_USERSLIST",
    GO_TO_USER_ADD: "GO_TO_USER_ADD",
    GO_TO_USER_EDIT: "GO_TO_USER_EDIT",
    USERLIST_LOADED: "USERLIST_LOADED",
    USER_ADDED: "USER_ADDED",
    AUTHENTICATION_ERROR: "AUTHENTICATION_ERROR",
    USER_ADDING_FAILED: "USER_ADDING_FAILED",
    GET_USERS: "GET_USERS",
    EDIT_USER: "EDIT_USER",
    DELETE_USER: "DELETE_USER",
    GET_AUTHDATA: "GET_AUTHDATA",
    DEACTIVATE_USER: "DEACTIVATE_USER",
    ACTIVATE_USER: "ACTIVATE_USER",
    SET_AVATAR: "SET_AVATAR",
    GET_AVATAR: "GET_AVATAR",
    GET_EMAIL: "GET_EMAIL",
  },
  organisations: {
    ADD_ORGANISATION: "ADD_ORGANISATION",
    EDIT_ORGANISATION: "EDIT_ORGANISATION",
    GET_ORGANISATION: "GET_ORGANISATION",
    SET_LOGO: "SET_LOGO",
    GET_LOGO: "GET_LOGO",
  },
  documents: {
    CHANGE_STATUS: "CHANGE_STATUS",
    GET_ATTACHMENT: "GET_ATTACHMENT",
    ADD_ATTACHMENT: "ADD_ATTACHMENT",
    ADD_DOCUMENT: "ADD_DOCUMENT",
    ADD_DOCUMENTS: "ADD_DOCUMENTS",
    DELETE_DOCUMENT: "DELETE_DOCUMENT",
    SAVE_DRAFT: "SAVE_DRAFT",
    WITDHRAW_DOCUMENT: "WITDHRAW_DOCUMENT",
    PASS_ID: "PASS_ID",
    SIGN_DOCUMENT: "SIGN_DOCUMENT",
    PASS_DOC_NAME: "PASS_DOC_NAME",
    IS_SEEN: "IS_SEEN",
    GET_CURRENT_DOCUMENT: "GET_CURRENT_DOCUMENT",
    REJECT_DOCUMENT: "REJECT_DOCUMENT",
    CHANGE_MODAL_VIEW: "CHANGE_MODAL_VIEW",
    SEND_DOCUMENT: "SEND_DOCUMENT",
    RESET_CURRENT_DOCUMENT: "RESET_CURRENT_DOCUMENT",
  },
  verificate: {
    VERIFICATE_DOCUMENT: "VERIFICATE_DOCUMENT",
  },
};

export default eventStatuses;
